import React, { createContext, useContext, useState } from "react";

type OfficeContextType = {
  currentOffice: number | undefined;
  setCurrentOffice: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const OfficeContext = createContext<OfficeContextType>({
  currentOffice: undefined,
  setCurrentOffice: () => undefined,
});

const useOfficeContext = (): OfficeContextType => {
  return useContext(OfficeContext);
};

const OfficeProvider = (props: { children: React.ReactNode }) => {
  const [currentOffice, setCurrentOffice] = useState<number | undefined>(undefined);

  return (
    <OfficeContext.Provider value={{ currentOffice, setCurrentOffice }}>
      {props.children}
    </OfficeContext.Provider>
  );
};

export { useOfficeContext, OfficeProvider };
