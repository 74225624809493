import React from "react";

type Props = {
  fullName: string;
};

export const LiteralAvatar: React.FC<Props> = ({ fullName }) => {
  return (
    <div className="bg-brand-avatar flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full text-xs font-bold text-white">
      {fullName.split(" ")[0]?.[0]?.toLocaleUpperCase()}
      {fullName.split(" ")[1]?.[0]?.toLocaleUpperCase()}
    </div>
  );
};
