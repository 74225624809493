import { Button, Select } from "@calcom/ui";

import { RowsPerPageOptions } from "@lib/constant";

type PaginationProps = {
  page: number;
  count: number;
  perPage: number;
  handleNext: (page: number) => void;
  handlePrev: (page: number) => void;
  handleRowsPerPage: (rows: number) => void;
};

const Pagination = ({ page, count, handleNext, handlePrev, handleRowsPerPage, perPage }: PaginationProps) => {
  const handlePrevClick = () => {
    if (page !== 0) handlePrev(page - 1);
  };

  const handleNextClick = () => {
    if (count - (page + 1) * perPage > 0) handleNext(page + 1);
  };

  const handleRowsPerPageChange = (rows: number) => {
    handleRowsPerPage(rows);
  };

  return (
    <div className="mt-4 flex flex-col sm:flex-row sm:items-center sm:justify-between">
      <nav className="mb-4 sm:order-1 sm:mb-0" role="navigation" aria-label="Navigation">
        <ul className="flex justify-center">
          <li className="ml-3 first:ml-0">
            <Button
              className={`${
                page !== 0 && " cursor-pointer"
              } btn border-slate-200 dark:border-slate-700 dark:bg-slate-800 ${
                page === 0
                  ? "bg-white text-slate-300 dark:text-slate-600"
                  : "text-white hover:border-slate-300 dark:hover:border-slate-600"
              }`}
              disabled={page == 0}
              onClick={handlePrevClick}>
              &lt;- Previous
            </Button>
          </li>
          <li className="ml-3 first:ml-0">
            <Button
              className={`btn border-slate-200 dark:border-slate-700 dark:bg-slate-800 ${
                count - (page + 1) * perPage > 0 ? "" : "bg-white text-slate-300 dark:text-slate-600"
              } ${count - (page + 1) * perPage > 0 && " cursor-pointer"}`}
              disabled={count - (page + 1) * perPage <= 0}
              onClick={handleNextClick}>
              Next -&gt;
            </Button>
          </li>
        </ul>
      </nav>
      <div className="flex items-center gap-1 text-center text-sm text-slate-500 dark:text-slate-400 sm:text-left">
        <div className="flex items-center gap-1">
          <span className="">Rows per page:</span>
          <div className="w-24">
            <Select
              options={RowsPerPageOptions}
              value={RowsPerPageOptions.find((option) => option.value === perPage)}
              onChange={(option) => {
                option && handleRowsPerPageChange(option.value);
              }}
            />
          </div>
        </div>
        <div>
          Showing{" "}
          <span className="font-medium text-slate-600 dark:text-slate-300">
            {count === 0 ? 0 : page * perPage + 1}
          </span>{" "}
          to{" "}
          <span className="font-medium text-slate-600 dark:text-slate-300">
            {page * perPage + (count - (page + 1) * perPage > 0 ? perPage : count - page * perPage)}
          </span>{" "}
          of <span className="font-medium text-slate-600 dark:text-slate-300">{count}</span> results
        </div>
      </div>
    </div>
  );
};

export default Pagination;
