import dynamic from "next/dynamic";
import { useState, useEffect } from "react";

import type { RouterOutputs } from "@calcom/trpc/react";
import { Select } from "@calcom/ui";

import { formatNumber } from "@lib/practice/helper";

const ApexCharts = dynamic(() => import("react-apexcharts"), { ssr: false });

type Metrics = RouterOutputs["viewer"]["payments"]["getMetrics"];

const selectOptions = [
  { value: 0, label: "Gross Payment Volume" },
  { value: 1, label: "Net Payment Volume" },
  { value: 2, label: "Spend / Patient" },
];

export default function LineChart({ timeRange, metrics }: { timeRange: string; metrics?: Metrics }) {
  const [trend, setTrend] = useState(0);
  const [total, setTotal] = useState(0);
  const [series, setSeries] = useState([]);

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    colors: ["#86CEBD"],
    stroke: {
      colors: ["#86CEBD"],
      lineCap: "",
      width: 6,
    },
    markers: {
      size: 5,
      colors: ["#86CEBD"],
      strokeColor: "#86CEBD",
      strokeWidth: 3,
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.55,
        opacityTo: 0,
        shade: "#86CEBD",
        gradientToColors: ["#86CEBD"],
      },
    },
    plotOptions: {},
    grid: {
      show: false,
      strokeDashArray: 4,
      padding: {
        left: 16,
        right: 16,
        top: -26,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        cssClass: "text-xs text-white font-medium",
      },
      formatter: function (value) {
        return "$" + formatNumber(value, "number").toString().split(".")[0];
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: -25,
      markers: {
        radius: 2,
        width: 12,
        height: 12,
      },
    },
    xaxis: {
      labels: {
        show: true,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      labels: {
        formatter: function (value) {
          return "$" + formatNumber(value, "number").toString().split(".")[0];
        },
      },
    },
  };

  const [selectedChart, setSelectedChart] = useState(selectOptions[0]);

  useEffect(() => {
    if (metrics) {
      if (selectedChart.value === 0) {
        setTrend(metrics.trendGross);
        setTotal(metrics.grossVolume);
      } else if (selectedChart.value === 1) {
        setTrend(metrics.trendNet);
        setTotal(metrics.netVolume);
      } else {
        setTrend(metrics.trendSpend);
        setTotal(metrics.spendPatient);
      }
      setSeries(metrics.chart[selectedChart.value].data);
    }
  }, [selectedChart, timeRange, metrics]);

  return (
    <div className="w-full rounded-md border border-gray-200 bg-white p-5 dark:bg-gray-800">
      <div className="mb-4 flex w-full border-b border-gray-200 pb-4 dark:border-gray-700">
        <div className="flex w-full items-center">
          <div className="w-full">
            <div className="mb-4 flex w-full flex-col justify-between gap-2 md:mb-0 md:flex-row">
              <div className="w-80">
                <Select
                  instanceId="office"
                  options={selectOptions}
                  styles={{
                    valueContainer: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: 0,
                      fontSize: "1rem",
                      fontWeight: 700,
                      color: "#000000",
                    }),
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: 0,
                      boxShadow: "none",
                      "&:focus-within": {
                        border: 0,
                        boxShadow: "none",
                      },
                    }),
                    input: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: 0,
                    }),
                    dropdownIndicator: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: "0 0 0 5px",
                      color: "black",
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      margin: 0,
                    }),
                    container: (baseStyles, state) => ({
                      ...baseStyles,
                      width: 250,
                    }),
                  }}
                  value={selectedChart}
                  onChange={(option) => {
                    setSelectedChart(option);
                  }}
                />
              </div>
              <div className="flex items-center gap-4">
                <p className="text-gray-500">
                  {timeRange} :{" "}
                  <span className="font-semibold text-black">${formatNumber(total, "balance")}</span>
                </p>
                {timeRange !== "All Time" && (
                  <>
                    {trend >= 0 ? (
                      <span className="flex items-center gap-2 rounded-sm bg-green-200 px-1 text-xs text-[#00A171]">
                        {formatNumber(Math.floor(trend), "number")} %{/* <IncreaseGreenIcon width={12} /> */}
                      </span>
                    ) : (
                      <span className="flex items-center gap-2 rounded-sm bg-red-200 px-1 text-xs text-red-800">
                        {formatNumber(Math.floor(trend), "number")} %{/* <DecreaseIcon width={12} />{" "} */}
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
            <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
              {selectedChart === selectOptions[0]
                ? "Visualize your revenue from all types of payments in chart format."
                : selectedChart === selectOptions[1]
                ? "Visualize your net revenue (minus fees and refunds) from all types of payments in chart format."
                : "Visualize your gross spending per patient from all types of payments in chart format."}
            </p>
          </div>
        </div>
      </div>
      <ApexCharts
        options={options}
        series={[{ name: selectedChart.label, data: series }]}
        type="area"
        height={318}
      />
    </div>
  );
}
