import React from "react";

import { SkeletonText } from "@calcom/ui";

export default function DonutchartSkeletonLoader() {
  return (
    <div className="flex h-full min-w-[365px] flex-col rounded-md border border-gray-200 bg-white p-5">
      <div className="flex flex-col gap-4 border-b pb-6">
        <SkeletonText className="h-4 w-[180px]" />
        <SkeletonText className="h-4 w-[200px]" />
      </div>
      <div className="my-6 flex gap-4">
        <SkeletonText className="h-4 w-[100px]" />
        <SkeletonText className="h-4 w-[100px]" />
      </div>
      <div className="flex h-full items-center">
        <div className="mx-auto flex h-[250px] w-[250px] animate-pulse items-center justify-center rounded-full bg-gray-300">
          <div className="flex h-[210px] w-[210px] flex-col items-center justify-center gap-2 rounded-full bg-white">
            <SkeletonText className="w-40" />
            <SkeletonText className="w-32" />
          </div>
        </div>
      </div>
    </div>
  );
}
