import type { User } from "@prisma/client";
import { signOut, useSession } from "next-auth/react";
import Head from "next/head";
import Link from "next/link";
import type { NextRouter } from "next/router";
import { useRouter } from "next/router";
import type { Dispatch, ReactNode, SetStateAction } from "react";
import React, { Fragment, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";

import dayjs from "@calcom/dayjs";
import { useIsEmbed } from "@calcom/embed-core/embed-iframe";
import HelpMenuItem from "@calcom/features/ee/support/components/HelpMenuItem";
import { KBarContent, KBarRoot } from "@calcom/features/kbar/Kbar";
import classNames from "@calcom/lib/classNames";
import { APP_NAME, WEBAPP_URL } from "@calcom/lib/constants";
import { useLocale } from "@calcom/lib/hooks/useLocale";
import useTheme from "@calcom/lib/hooks/useTheme";
import { trpc } from "@calcom/trpc/react";
import useMeQuery from "@calcom/trpc/react/hooks/useMeQuery";
import type { SVGComponent } from "@calcom/types/SVGComponent";
import {
  Button,
  Dropdown,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
  ErrorBoundary,
  Logo,
  HeadSeo,
  SkeletonText,
  Tooltip,
} from "@calcom/ui";
import { FiLogOut, FiArrowRight, FiArrowLeft, FiMenu } from "@calcom/ui/components/icon";
import {
  BillingARIcon,
  ChargeIcon,
  DownArrowIcon,
  OutreachIcon,
  PatientIcon,
  PracticeIcon,
  NewUsersIcon,
  NewInsightsIcon,
  NewGroupIcon,
  MembershipIcon,
  PaymentPlanIcon,
  HomeIcon,
  PaymentMetricsIcon,
  PayoutIcon,
  UserIcon,
  SettingIcon,
} from "@calcom/web/components/Icons";

/* TODO: Migate this */

export const ONBOARDING_INTRODUCED_AT = dayjs("September 1 2021").toISOString();

export const ONBOARDING_NEXT_REDIRECT = {
  redirect: {
    permanent: false,
    destination: "/getting-started",
  },
} as const;

export const shouldShowOnboarding = (user: Pick<User, "createdDate" | "completedOnboarding">) => {
  return !user.completedOnboarding && dayjs(user.createdDate).isAfter(ONBOARDING_INTRODUCED_AT);
};

function useRedirectToLoginIfUnauthenticated(isPublic = false) {
  const { data: session, status } = useSession();
  const loading = status === "loading";
  const router = useRouter();

  useEffect(() => {
    if (isPublic) {
      return;
    }

    if (!loading && !session) {
      router.replace({
        pathname: "/auth/login",
        query: {
          callbackUrl: `${WEBAPP_URL}${location.pathname}${location.search}`,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, session, isPublic]);

  return {
    loading: loading && !session,
    session,
  };
}

function useRedirectToOnboardingIfNeeded() {
  const router = useRouter();
  const query = useMeQuery();
  const user = query.data;

  const isRedirectingToOnboarding = user && shouldShowOnboarding(user);

  useEffect(() => {
    if (isRedirectingToOnboarding) {
      router.replace({
        pathname: "/getting-started",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRedirectingToOnboarding]);
  return {
    isRedirectingToOnboarding,
  };
}

const Layout = (props: LayoutProps) => {
  const { data: user } = useMeQuery();
  const pageTitle = typeof props.heading === "string" && !props.title ? props.heading : props.title;

  return (
    <>
      <Head>
        <style>
          {`body {
            --brand-avatar-color: ${user?.avatarColor || "#193E52"};
            --brand-primary-color: ${user?.sidebarColor || "#1C4851"};
            --brand-button-color: ${user?.buttonColor || "#F09375"};
            --brand-page-color: ${user?.pageColor || "#FFFCF6"};
          `}
        </style>
      </Head>
      {!props.withoutSeo && (
        <HeadSeo
          title={pageTitle ?? APP_NAME}
          description={props.subtitle ? props.subtitle?.toString() : ""}
          officeName={user?.name ?? ""}
        />
      )}
      <div>
        <Toaster position="bottom-right" />
      </div>

      {/* todo: only run this if timezone is different */}
      {/*<TimezoneChangeDialog />*/}
      <div className="relative min-h-screen w-full bg-[#F3F5F5]">
        {/* <div className="bg-brand-primary min-h-screen absolute -z-10 h-full w-1/2" />
        <div className="bg-brand-page absolute left-1/2 -z-10 h-full w-1/2" /> */}
        <div className="m-auto flex min-h-screen max-w-[1440px] flex-col">
          {/*<div className="divide-y divide-black">*/}
          {/*  <AdminPasswordBanner />*/}
          {/*</div>*/}
          <div className="flex flex-1" data-testid="dashboard-shell">
            {props.SidebarContainer || <SideBarContainer logo={user?.logo} />}
            <div className="flex w-0 flex-1 flex-col">
              <MainContainer {...props} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

type DrawerState = [isOpen: boolean, setDrawerOpen: Dispatch<SetStateAction<boolean>>];

type LayoutProps = {
  centered?: boolean;
  title?: string;
  heading?: ReactNode;
  subtitle?: ReactNode;
  officeName?: string | null;
  headerClassName?: string;
  children: ReactNode;
  logo?: string | null;
  sidebarColor?: string;
  buttonColor?: string;
  pageColor?: string;
  newPatientColor?: string;
  existingPatientColor?: string;
  internalColor?: string;
  CTA?: ReactNode;
  large?: boolean;
  MobileNavigationContainer?: ReactNode;
  SidebarContainer?: ReactNode;
  TopNavContainer?: ReactNode;
  drawerState?: DrawerState;
  HeadingLeftIcon?: ReactNode;
  backPath?: string | boolean; // renders back button to specified path
  // use when content needs to expand with flex
  flexChildrenContainer?: boolean;
  isPublic?: boolean;
  withoutMain?: boolean;
  // Gives you the option to skip HeadSEO and render your own.
  withoutSeo?: boolean;
  // Gives the ability to include actions to the right of the heading
  actions?: JSX.Element;
  smallHeading?: boolean;
};

const KBarWrapper = ({ children, withKBar = false }: { withKBar: boolean; children: React.ReactNode }) =>
  withKBar ? (
    <KBarRoot>
      {children}
      <KBarContent />
    </KBarRoot>
  ) : (
    <>{children}</>
  );

const PublicShell = (props: LayoutProps) => {
  const { status } = useSession();
  return (
    <KBarWrapper withKBar={status === "authenticated"}>
      {/*<CustomBrandingContainer />*/}
      <Layout {...props} />
    </KBarWrapper>
  );
};

export default function Shell(props: LayoutProps) {
  // if a page is unauthed and isPublic is true, the redirect does not happen.
  useRedirectToLoginIfUnauthenticated(props.isPublic);
  useRedirectToOnboardingIfNeeded();
  useTheme("light");

  return !props.isPublic ? (
    <KBarWrapper withKBar>
      {/*<CustomBrandingContainer />*/}
      <Layout {...props} />
    </KBarWrapper>
  ) : (
    <PublicShell {...props} />
  );
}

export function UserDropdown({ small }: { small?: boolean }) {
  const { t } = useLocale();
  const query = useMeQuery();
  const user = query.data;
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const Beacon = window.Beacon;
    // window.Beacon is defined when user actually opens up HelpScout and username is available here. On every re-render update session info, so that it is always latest.
    Beacon &&
      Beacon("session-data", {
        username: user?.username || "Unknown",
        screenResolution: `${screen.width}x${screen.height}`,
      });
  });
  const utils = trpc.useContext();
  const [helpOpen, setHelpOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  if (!user) {
    return null;
  }
  const onHelpItemSelect = () => {
    setHelpOpen(false);
    setMenuOpen(false);
  };

  // Prevent rendering dropdown if user isn't available.
  // We don't want to show nameless user.
  if (!user) {
    return null;
  }
  return (
    <Dropdown open={menuOpen}>
      <div className="">
        <DropdownMenuTrigger asChild onClick={() => setMenuOpen((menuOpen) => !menuOpen)}>
          <button className="group mx-0 flex w-full cursor-pointer appearance-none items-center rounded-full text-left outline-none focus:outline-none focus:ring-0 md:rounded-none lg:rounded">
            {!small && (
              <Tooltip
                content={
                  <span className="block truncate py-1 font-normal leading-none text-gray-800">
                    {user.email}
                  </span>
                }
                className="bg-white">
                {/* <span className="flex flex-grow items-center truncate">
                  <span className="flex-grow truncate text-sm">
                    <span className="mb-1 block truncate font-medium leading-none text-gray-100">
                      {user.name || "Nameless User"}
                    </span>
                    <span className="block truncate font-normal leading-none text-gray-200">
                      {user.email
                        ? process.env.NEXT_PUBLIC_WEBSITE_URL === "https://cal.com"
                          ? `cal.com/${user.username}`
                          : `/${user.email}`
                        : "No public page"}
                    </span>
                  </span>
                  <FiMoreVertical
                    className="h-4 w-4 flex-shrink-0 text-gray-100 ltr:mr-2 rtl:ml-2 rtl:mr-4"
                    aria-hidden="true"
                  />
                </span> */}
                <div className="flex h-10 w-10 items-center justify-center rounded-md border border-gray-200 p-2">
                  <UserIcon />
                </div>
              </Tooltip>
            )}
          </button>
        </DropdownMenuTrigger>
      </div>

      <DropdownMenuPortal>
        <DropdownMenuContent
          onInteractOutside={() => {
            setMenuOpen(false);
            setHelpOpen(false);
          }}
          className="overflow-hidden rounded-md">
          {helpOpen ? (
            <HelpMenuItem onHelpItemSelect={() => onHelpItemSelect()} />
          ) : (
            <>
              {user.username && <></>}
              <DropdownMenuItem className="w-[12rem]">
                <DropdownItem
                  type="button"
                  StartIcon={(props) => <FiLogOut aria-hidden="true" {...props} />}
                  onClick={() => signOut({ callbackUrl: "/auth/logout" })}>
                  {t("sign_out")}
                </DropdownItem>
              </DropdownMenuItem>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </Dropdown>
  );
}

export type NavigationItemType = {
  name: string;
  href: string;
  badge?: React.ReactNode;
  icon?: SVGComponent;
  child?: NavigationItemType[];
  pro?: true;
  applyIntent?: boolean;
  hasSeparator?: boolean;
  isLabel?: boolean;
  hasLine?: boolean;
  isNotReady?: boolean;
  onlyMobile?: boolean;
  onlyDesktop?: boolean;
  isCurrent?: ({
    item,
    isChild,
    router,
  }: {
    item: NavigationItemType;
    isChild?: boolean;
    router: NextRouter;
  }) => boolean;
};

const requiredCredentialNavigationItems = ["Routing Forms"];
const MORE_SEPARATOR_NAME = "more";

const navigation: NavigationItemType[] = [
  {
    name: "Home",
    href: "/practice",
    icon: HomeIcon,
    isCurrent: ({ router, item }) => {
      const path = router.asPath.split("?")[0];
      return path.startsWith(item.href);
    },
  },
  {
    name: "Manage Team",
    href: "/users",
    icon: NewUsersIcon,
    hasSeparator: true,
    isCurrent: ({ router, item }) => {
      const path = router.asPath.split("?")[0];
      return path.startsWith(item.href);
    },
  },
  {
    name: "Manage Location",
    href: "/",
    icon: NewUsersIcon,
    hasSeparator: true,
    isLabel: true,
    isCurrent: ({ router, item }) => {
      const path = router.asPath.split("?")[0];
      return path.startsWith(item.href);
    },
  },
  {
    name: "Patients",
    href: "/patients",
    icon: PatientIcon,
    isCurrent: ({ router, item }) => {
      const path = router.asPath.split("?")[0];
      return path.startsWith(item.href);
    },
  },
  {
    name: "Transactions",
    href: "/charges",
    icon: ChargeIcon,
    isCurrent: ({ router, item }) => {
      const path = router.asPath.split("?")[0];
      return path.startsWith(item.href);
    },
  },
  // {
  //   name: "Insights",
  //   href: "/insights",
  //   icon: InsightsIcon,
  //   isCurrent: ({ router, item }) => {
  //     const path = router.asPath.split("?")[0];
  //     return path.startsWith(item.href);
  //   },
  // },
  {
    name: "Outreach",
    href: "/outreach",
    icon: OutreachIcon,
    isCurrent: ({ router, item }) => {
      const path = router.asPath.split("?")[0];
      return path.startsWith(item.href);
    },
  },
  {
    name: "Payouts",
    href: "/payouts",
    icon: PayoutIcon,
    isCurrent: ({ router, item }) => {
      const path = router.asPath.split("?")[0];
      return path.startsWith(item.href);
    },
  },
  {
    name: "Practice Settings",
    href: "/setting",
    icon: SettingIcon,
    isCurrent: ({ router, item }) => {
      const path = router.asPath.split("?")[0];
      return path.startsWith(item.href);
    },
  },
  // {
  //   name: "Reporting",
  //   href: "/report",
  //   icon: NewReportIcon,
  //   isCurrent: ({ router, item }) => {
  //     const path = router.asPath.split("?")[0];
  //     return path.startsWith(item.href);
  //   },
  // },
  {
    name: "BILLING FUNCTION",
    href: "",
    icon: NewUsersIcon,
    hasSeparator: true,
    isLabel: true,
    isCurrent: ({ router, item }) => {
      const path = router.asPath.split("?")[0];
      return path.startsWith(item.href);
    },
  },
  {
    name: "A/R Automation",
    href: "/billingar",
    icon: BillingARIcon,
    isCurrent: ({ router }) => {
      const path = router.asPath.split("?")[0];
      return (
        path.endsWith("/billingar") ||
        path.endsWith("/outstanding") ||
        path.endsWith("/scheduled") ||
        path.endsWith("/engaged") ||
        path.endsWith("/follow_up") ||
        path.endsWith("/blocked") ||
        path.endsWith("/automation")
      );
    },
    child: [
      {
        name: "Outstanding",
        href: "/billing_ar/outstanding",
        applyIntent: true,
        isCurrent: ({ router, item }) => {
          const path = router.asPath.split("?")[0];
          return path.startsWith(item.href);
        },
      },
      {
        name: "Scheduled",
        href: "/billing_ar/scheduled",
        applyIntent: true,
        isCurrent: ({ router, item }) => {
          const path = router.asPath.split("?")[0];
          return path.startsWith(item.href);
        },
      },
      {
        name: "Engaged",
        href: "/billing_ar/engaged",
        applyIntent: true,
        isCurrent: ({ router, item }) => {
          const path = router.asPath.split("?")[0];
          return path.startsWith(item.href);
        },
      },
      {
        name: "Follow Up",
        href: "/billing_ar/follow_up",
        applyIntent: true,
        isCurrent: ({ router, item }) => {
          const path = router.asPath.split("?")[0];
          return path.startsWith(item.href);
        },
      },
      {
        name: "Blocked",
        href: "/billing_ar/blocked",
        applyIntent: true,
        isCurrent: ({ router, item }) => {
          const path = router.asPath.split("?")[0];
          return path.startsWith(item.href);
        },
      },
    ],
  },
  {
    name: "Memberships",
    href: "/memberships",
    icon: MembershipIcon,
    isNotReady: false,
    isCurrent: ({ router, item }) => {
      const path = router.asPath.split("?")[0];
      return path.startsWith(item.href) || path.endsWith("/manage-memberships");
    },
  },
  {
    name: "Payment Plans",
    href: "/paymentplans",
    icon: PaymentPlanIcon,
    isNotReady: false,
    isCurrent: ({ router, item }) => {
      const path = router.asPath.split("?")[0];
      return path.startsWith(item.href) || path.endsWith("/payment-plan-templates");
    },
  },
];

const groupNavigation: NavigationItemType[] = [
  {
    name: "Group Home",
    href: "/home",
    icon: NewGroupIcon,
    isCurrent: ({ router }) => {
      const path = router.asPath.split("?")[0];
      return path.endsWith("/home");
    },
  },
  {
    name: "Payment Metrics",
    href: "/transactions",
    icon: PaymentMetricsIcon,
    isCurrent: ({ router, item }) => {
      const path = router.asPath.split("?")[0];
      return path.startsWith(item.href);
    },
  },
  {
    name: "A/R Insights",
    href: "/insights",
    icon: NewInsightsIcon,
    isCurrent: ({ router, item }) => {
      const path = router.asPath.split("?")[0];
      return path.startsWith(item.href);
    },
  },
  {
    name: "Manage Team",
    href: "/users",
    icon: NewUsersIcon,
    isCurrent: ({ router, item }) => {
      const path = router.asPath.split("?")[0];
      return path.startsWith(item.href);
    },
  },
  {
    name: "LOCATION VIEW",
    href: "/",
    isNotReady: false,
    isLabel: true,
    hasLine: true,
    isCurrent: ({ router, item }) => {
      const path = router.asPath.split("?")[0];
      return path.startsWith(item.href);
    },
  },
  {
    name: "Practice Home",
    href: "/practice",
    icon: PracticeIcon,
    isCurrent: ({ router }) => {
      const path = router.asPath.split("?")[0];
      return path.endsWith("/practice");
    },
  },
  {
    name: "Patient Table",
    href: "/patients",
    icon: PatientIcon,
    isCurrent: ({ router, item }) => {
      const path = router.asPath.split("?")[0];
      return path.startsWith(item.href);
    },
  },
  {
    name: "Transactions",
    href: "/charges",
    icon: ChargeIcon,
    isCurrent: ({ router, item }) => {
      const path = router.asPath.split("?")[0];
      return path.startsWith(item.href);
    },
  },
  {
    name: "Outreach",
    href: "/outreach",
    icon: OutreachIcon,
    isCurrent: ({ router, item }) => {
      const path = router.asPath.split("?")[0];
      return path.startsWith(item.href);
    },
  },
  {
    name: "Bank Payouts",
    href: "/payouts",
    icon: PayoutIcon,
    isCurrent: ({ router, item }) => {
      const path = router.asPath.split("?")[0];
      return path.startsWith(item.href);
    },
  },
  {
    name: "Practice Settings",
    href: "/setting",
    icon: SettingIcon,
    isCurrent: ({ router, item }) => {
      const path = router.asPath.split("?")[0];
      return path.startsWith(item.href);
    },
  },
  // {
  //   name: "Reporting",
  //   href: "/report",
  //   hasSeparator: true,
  //   icon: NewReportIcon,
  //   isCurrent: ({ router, item }) => {
  //     const path = router.asPath.split("?")[0];
  //     return path.startsWith(item.href);
  //   },
  // },
  {
    name: "BILLING FUNCTION",
    href: "/",
    isNotReady: false,
    isLabel: true,
    hasLine: true,
    isCurrent: ({ router, item }) => {
      const path = router.asPath.split("?")[0];
      return path.startsWith(item.href);
    },
  },
  {
    name: "A/R Automation",
    href: "/billingar",
    icon: BillingARIcon,
    isCurrent: ({ router }) => {
      const path = router.asPath.split("?")[0];
      return (
        path.endsWith("/billingar") ||
        path.endsWith("/outstanding") ||
        path.endsWith("/scheduled") ||
        path.endsWith("/engaged") ||
        path.endsWith("/follow_up") ||
        path.endsWith("/blocked") ||
        path.endsWith("/automation")
      );
    },
    child: [
      {
        name: "Outstanding",
        href: "/billing_ar/outstanding",
        applyIntent: true,
        isCurrent: ({ router, item }) => {
          const path = router.asPath.split("?")[0];
          return path.startsWith(item.href);
        },
      },
      {
        name: "Scheduled",
        href: "/billing_ar/scheduled",
        applyIntent: true,
        isCurrent: ({ router, item }) => {
          const path = router.asPath.split("?")[0];
          return path.startsWith(item.href);
        },
      },
      {
        name: "Engaged",
        href: "/billing_ar/engaged",
        applyIntent: true,
        isCurrent: ({ router, item }) => {
          const path = router.asPath.split("?")[0];
          return path.startsWith(item.href);
        },
      },
      {
        name: "Follow Up",
        href: "/billing_ar/follow_up",
        applyIntent: true,
        isCurrent: ({ router, item }) => {
          const path = router.asPath.split("?")[0];
          return path.startsWith(item.href);
        },
      },
      {
        name: "Blocked",
        href: "/billing_ar/blocked",
        applyIntent: true,
        isCurrent: ({ router, item }) => {
          const path = router.asPath.split("?")[0];
          return path.startsWith(item.href);
        },
      },
    ],
  },
  {
    name: "Patient Memberships",
    href: "/memberships",
    icon: MembershipIcon,
    isNotReady: false,
    isCurrent: ({ router, item }) => {
      const path = router.asPath.split("?")[0];
      return path.startsWith(item.href) || path.endsWith("/manage-memberships");
    },
  },
  {
    name: "Payment Plans",
    href: "/paymentplans",
    icon: PaymentPlanIcon,
    isNotReady: false,
    isCurrent: ({ router, item }) => {
      const path = router.asPath.split("?")[0];
      return path.startsWith(item.href) || path.endsWith("/payment-plan-templates");
    },
  },
];

const moreSeparatorIndex = navigation.findIndex((item) => item.name === MORE_SEPARATOR_NAME);
// We create all needed navigation items for the different use cases
const { desktopNavigationItems, mobileNavigationBottomItems, mobileNavigationMoreItems } = navigation.reduce<
  Record<string, NavigationItemType[]>
>(
  (items, item, index) => {
    // We filter out the "more" separator in` desktop navigation
    if (item.name !== MORE_SEPARATOR_NAME) items.desktopNavigationItems.push(item);
    // Items for mobile bottom navigation
    if (index < moreSeparatorIndex + 1 && !item.onlyDesktop) items.mobileNavigationBottomItems.push(item);
    // Items for the "more" menu in mobile navigation
    else items.mobileNavigationMoreItems.push(item);
    return items;
  },
  { desktopNavigationItems: [], mobileNavigationBottomItems: [], mobileNavigationMoreItems: [] }
);

const Navigation = () => {
  const { data: user, isLoading } = trpc.viewer.me.useQuery();

  if (isLoading) return null;

  const navigationItems = user?.isGroupView ? groupNavigation : navigation;

  return (
    <nav className="mt-2 flex-1 md:px-2 lg:mt-[15px] lg:px-0">
      {navigationItems.map((item, index) => (
        <div key={index}>
          <NavigationItem item={item} />
          {/* {item.hasSeparator && <div className="mb-2 border-b opacity-25" />} */}
        </div>
      ))}
      {/* <div className="mt-0.5 text-gray-500 lg:hidden">
        <KBarTrigger />
      </div> */}
    </nav>
  );
};

function useShouldDisplayNavigationItem(item: NavigationItemType) {
  return !requiredCredentialNavigationItems.includes(item.name);
}

const defaultIsCurrent: NavigationItemType["isCurrent"] = ({ isChild, item, router }) => {
  return isChild ? item.href === router.asPath : router.asPath.startsWith(item.href);
};

const NavigationItem: React.FC<{
  index?: number;
  item: NavigationItemType;
  isChild?: boolean;
}> = (props) => {
  const { item, isChild, index } = props;
  const { t, isLocaleReady } = useLocale();
  const router = useRouter();
  const isCurrent: NavigationItemType["isCurrent"] = item.isCurrent || defaultIsCurrent;
  const current = isCurrent({ isChild: !!isChild, item, router });
  const shouldDisplayNavigationItem = useShouldDisplayNavigationItem(props.item);

  if (!shouldDisplayNavigationItem) return null;

  return (
    <Fragment>
      {item.isLabel ? (
        <div
          className={classNames(
            "flex w-full items-center justify-between pl-6 pr-1 pb-3 text-sm font-normal text-gray-600",
            item.hasLine ? "mt-4 border-t border-gray-200 pt-4" : ""
          )}>
          <span>{item.name}</span>
          {/* <DownArrowIcon
            className={classNames("h-3 w-3 rotate-90 items-center", current ? "rotate-180" : "")}
          /> */}
        </div>
      ) : (
        <Link
          href={item.isNotReady ? "" : item.href}
          aria-label={t(item.name)}
          className={classNames(
            "group my-1 flex items-center rounded border border-l-4 border-transparent border-l-transparent py-[10px] pl-5 pr-1 text-sm font-medium text-white hover:bg-[#F9FAFB] [&[aria-current='page']]:border [&[aria-current='page']]:border-l-4 [&[aria-current='page']]:border-gray-200 [&[aria-current='page']]:border-l-black [&[aria-current='page']]:bg-[#F9FAFB]",
            // item.applyIntent ? "lg:pl-9" : "",
            isChild
              ? `mt-px hidden pl-11 pr-2 lg:flex [&[aria-current='page']]:bg-[#F9FAFB] [&[aria-current='page']]:text-white`
              : "mt-0.5 text-sm [&[aria-current='page']]:text-white",
            item.isNotReady ? "cursor-not-allowed opacity-50" : ""
          )}
          aria-current={current ? "page" : undefined}>
          {item.icon && (
            <item.icon
              className="h-4 w-4 flex-shrink-0 text-white ltr:mr-2 rtl:ml-2 [&[aria-current='page']]:text-inherit"
              aria-hidden="true"
              aria-current={current ? "page" : undefined}
            />
          )}
          {isLocaleReady ? (
            <span className="hidden w-full items-center justify-between font-medium text-gray-600 lg:flex">
              <div className="flex">{t(item.name)}</div>
              {item.badge && (
                <span className="bg-brand-button flex h-5 w-5 items-center justify-center rounded-full text-[10px] !text-white">
                  {item.badge}
                </span>
              )}
              {item.child && (
                <DownArrowIcon
                  className={classNames("h-3 w-3 rotate-90 items-center", current ? "rotate-180" : "")}
                />
              )}
            </span>
          ) : (
            <SkeletonText className="h-3 w-32" />
          )}
        </Link>
      )}
      {!item.child && item.hasSeparator && <div className="mb-2 border-b opacity-25" />}
      {item.child &&
        isCurrent({ router, isChild, item }) &&
        item.child.map((item, index) => <NavigationItem index={index} key={item.name} item={item} isChild />)}
    </Fragment>
  );
};

function MobileNavigationContainer() {
  const { status } = useSession();
  if (status !== "authenticated") return null;
  return <MobileNavigation />;
}

const MobileNavigation = () => {
  const isEmbed = useIsEmbed();

  return (
    <>
      <nav
        className={classNames(
          "pwa:pb-2.5 fixed bottom-0 z-30 -mx-4 flex w-full border border-t border-gray-200 bg-gray-50 bg-opacity-40 px-1 shadow backdrop-blur-md md:hidden",
          isEmbed && "hidden"
        )}>
        {/*{navigation.map((item) => (*/}
        {/*  <MobileNavigationItem key={item.name} item={item} />*/}
        {/*))}*/}
      </nav>
      {/* add padding to content for mobile navigation*/}
      <div className="block pt-12 md:hidden" />
    </>
  );
};

const MobileNavigationItem: React.FC<{
  item: NavigationItemType;
  isChild?: boolean;
}> = (props) => {
  const { item, isChild } = props;
  const router = useRouter();
  const { t, isLocaleReady } = useLocale();
  const isCurrent: NavigationItemType["isCurrent"] = item.isCurrent || defaultIsCurrent;
  const current = isCurrent({ isChild: !!isChild, item, router });
  const shouldDisplayNavigationItem = useShouldDisplayNavigationItem(props.item);

  if (!shouldDisplayNavigationItem) return null;
  return (
    <Fragment>
      <Link
        key={item.name}
        href={item.href}
        className="[&[aria-current='page']]:bg-brand-button relative my-2 flex min-w-0 flex-1 justify-start overflow-hidden rounded-md !bg-transparent p-1 pl-5 text-left text-xs font-medium text-gray-400 hover:text-gray-700 focus:z-10 sm:text-sm [&[aria-current='page']]:text-gray-900"
        aria-current={current ? "page" : undefined}>
        <div className="mr-auto flex flex-col items-center justify-center">
          {item.badge && <div className="absolute right-1 top-1">{item.badge}</div>}
          {/* {item.icon && (
            <item.icon
              className="mb-1 block h-5 w-5 flex-shrink-0 text-left text-inherit [&[aria-current='page']]:text-gray-900"
              aria-hidden="true"
              aria-current={current ? "page" : undefined}
            />
          )} */}
          {isLocaleReady ? (
            <span
              className={classNames("block truncate text-xl", current ? "text-brand-button" : "text-white")}>
              {t(item.name)}
            </span>
          ) : (
            <SkeletonText />
          )}
        </div>
      </Link>
      {item.child &&
        // isCurrent({ router, isChild, item }) &&
        item.child.map((item, _index) => (
          <div key={item.name} className="pl-4">
            <MobileNavigationItem key={item.name} item={item} isChild />
          </div>
        ))}
    </Fragment>
  );
};

const MobileNavigationMoreItem: React.FC<{
  item: NavigationItemType;
  isChild?: boolean;
}> = (props) => {
  const { item } = props;
  const { t, isLocaleReady } = useLocale();
  const shouldDisplayNavigationItem = useShouldDisplayNavigationItem(props.item);

  if (!shouldDisplayNavigationItem) return null;

  return (
    <li className="border-b last:border-b-0" key={item.name}>
      <Link href={item.href} className="flex items-center justify-between p-5 hover:bg-gray-100">
        <span className="flex items-center font-semibold text-gray-700 ">
          {item.icon && <item.icon className="h-5 w-5 flex-shrink-0 ltr:mr-3 rtl:ml-3" aria-hidden="true" />}
          {isLocaleReady ? t(item.name) : <SkeletonText />}
        </span>
        <FiArrowRight className="h-5 w-5 text-gray-500" />
      </Link>
    </li>
  );
};

function SideBarContainer({ logo }: { logo?: string | null }) {
  const { status } = useSession();
  const router = useRouter();

  // Make sure that Sidebar is rendered optimistically so that a refresh of pages when logged in have SideBar from the beginning.
  // This improves the experience of refresh on app store pages(when logged in) which are SSG.
  // Though when logged out, app store pages would temporarily show SideBar until session status is confirmed.
  if (status !== "loading" && status !== "authenticated") return null;
  if (router.route.startsWith("/v2/settings/")) return null;
  return <SideBar logo={logo} />;
}

function SideBar({ logo }: { logo?: string | null }) {
  return (
    <div className="relative">
      <aside className="desktop-transparent top-0 hidden h-full max-h-screen w-14 flex-col overflow-y-auto overflow-x-hidden border-r border-gray-100 pt-[50px] md:sticky md:flex lg:w-52">
        <div className="flex h-full flex-col justify-between py-2">
          <header className="items-center justify-between md:hidden lg:flex">
            {/*<Link href="/event-types" className="px-2">*/}
            {/* <Logo path={logo} /> */}
            {/*</Link>*/}
          </header>

          <Navigation />
        </div>

        <div>
          {/* <Tips /> */}
          {/* <div data-testid="user-dropdown-trigger">
            <span className="hidden lg:inline">
              <UserDropdown />
            </span>
            <span className="hidden md:inline lg:hidden">
              <UserDropdown small />
            </span>
          </div> */}
          {/*<Credits />*/}
        </div>
      </aside>
    </div>
  );
}

export function ShellMain(props: LayoutProps) {
  const router = useRouter();
  const { isLocaleReady } = useLocale();

  return (
    <>
      <div
        className={classNames(
          "flex items-center md:mt-0 md:mb-6",
          props.smallHeading ? "lg:mb-7" : "lg:mb-8"
        )}>
        {!!props.backPath && (
          <Button
            variant="icon"
            size="sm"
            color="minimal"
            onClick={() =>
              typeof props.backPath === "string" ? router.push(props.backPath as string) : router.back()
            }
            StartIcon={FiArrowLeft}
            aria-label="Go Back"
            className="rounded-md ltr:mr-2 rtl:ml-2"
          />
        )}
        {props.heading && (
          <header className={classNames(props.large && "py-8", "flex w-full max-w-full items-center")}>
            {props.HeadingLeftIcon && <div className="ltr:mr-4">{props.HeadingLeftIcon}</div>}
            <div className={classNames("w-full ltr:mr-4 rtl:ml-4 md:block", props.headerClassName)}>
              {props.heading && (
                <h3
                  className={classNames(
                    "max-w-28 sm:max-w-72 md:max-w-80 hidden truncate text-xl font-bold text-black md:block xl:max-w-full",
                    props.smallHeading ? "text-base" : "text-xl"
                  )}>
                  {!isLocaleReady ? <SkeletonText invisible /> : props.heading}
                </h3>
              )}
              {props.subtitle && (
                <div className="hidden text-sm text-gray-500 md:block">
                  {!isLocaleReady ? <SkeletonText invisible /> : props.subtitle}
                </div>
              )}
            </div>
            {props.CTA && (
              <div
                className={classNames(
                  props.backPath
                    ? "relative"
                    : "pwa:bottom-24 fixed bottom-20 z-40 ltr:right-4 rtl:left-4 md:z-auto md:ltr:right-0 md:rtl:left-0",
                  "flex-shrink-0 md:relative md:bottom-auto md:right-auto"
                )}>
                {props.CTA}
              </div>
            )}
            {props.actions && props.actions}
          </header>
        )}
      </div>
      <div className={classNames(props.flexChildrenContainer && "flex flex-1 flex-col")}>
        {props.children}
      </div>
    </>
  );
}

function MainContainer({
  MobileNavigationContainer: MobileNavigationContainerProp = <MobileNavigationContainer />,
  TopNavContainer: TopNavContainerProp,
  ...props
}: LayoutProps) {
  return (
    <main className="relative z-0 flex-1 focus:outline-none">
      {/* show top navigation for md and smaller (tablet and phones) */}
      {TopNavContainerProp}
      <div
        className="mt-12 max-w-full border-0 border-gray-200 py-4 px-4 pt-40 md:ml-6 md:border-l md:py-8 lg:px-6"
        style={{ height: "calc(100% - 50px)" }}>
        <ErrorBoundary>
          {!props.withoutMain ? <ShellMain {...props}>{props.children}</ShellMain> : props.children}
        </ErrorBoundary>
        {/* show bottom navigation for md and smaller (tablet and phones) on pages where back button doesn't exist */}
        {!props.backPath ? MobileNavigationContainerProp : null}
      </div>
    </main>
  );
}

export const MobileNavigationMoreItems = () => (
  <ul className="mt-2 rounded-md border">
    {mobileNavigationMoreItems.map((item) => (
      <MobileNavigationMoreItem key={item.name} item={item} />
    ))}
  </ul>
);

function TopNavContainer() {
  const { status } = useSession();
  if (status !== "authenticated") return null;
  return <TopNav />;
}

function TopNav() {
  const isEmbed = useIsEmbed();
  const { t } = useLocale();
  const [open, setOpen] = useState(false);
  return (
    <>
      <nav
        style={isEmbed ? { display: "none" } : {}}
        className="bg-brand-primary sticky top-0 z-40 flex w-full items-center justify-between border-b border-gray-200 bg-gray-50 px-4 py-3 sm:p-4 md:hidden">
        <Link href="/home">
          <Logo small />
        </Link>
        <div className="flex items-center gap-2 self-center">
          <button className="p-1 text-gray-400 hover:text-gray-500" onClick={() => setOpen(true)}>
            <FiMenu size={28} />
          </button>
        </div>
      </nav>
      <div
        className={classNames(
          "no-scrollbar bg-brand-primary fixed z-[99] h-full w-full transition-all md:hidden",
          open ? "ml-0" : "ml-[100%]"
        )}>
        {/* <div>
          <div className="flex flex-col gap-2 px-5">
            {navigation.map((item, key) => (
              <Link href={item.href} key={key} className={item.isCurrent ? "bg-red-700" : "bg-white"}>
                {item.name}
              </Link>
            ))}
          </div>
        </div> */}
        <nav className="mt-2 flex-1 md:px-2 lg:mt-6 lg:px-0">
          {desktopNavigationItems.map((item, index) => (
            <div key={index}>
              <MobileNavigationItem item={item} />
              {/* {index === 0 && <div className="mb-2 border-b opacity-25" />} */}
            </div>
          ))}
          {/* <div className="mt-0.5 text-gray-500 lg:hidden">
            <KBarTrigger />
          </div> */}
        </nav>
        <div className="mt-10 flex justify-center p-2" color="">
          <Button
            onClick={() => {
              setOpen(false);
            }}>
            Close
          </Button>
        </div>
      </div>
    </>
  );
}
