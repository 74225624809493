import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  OfficeStatus,
  UserPermissionRole,
  TransactionStatus,
  PaymentSource,
  PaymentType,
} from "@prisma/client";
import classNames from "classnames";
import { officeContext } from "context/officeProvider";
import type { GetServerSidePropsContext } from "next";
import Link from "next/link";
import { ChargeDialog } from "pages/charges";
import { ReportDialog } from "pages/reporting";
import Papa from "papaparse";
import type { ChangeEvent } from "react";
import React, { useContext, useEffect, useRef, useState } from "react";

import dayjs from "@calcom/dayjs";
import Shell from "@calcom/features/shell/Shell";
import { formatPMS } from "@calcom/lib/format";
import { useLocale } from "@calcom/lib/hooks/useLocale";
import { getOfficeIdsByUserRole } from "@calcom/lib/server/getOfficeIdsByUserRole";
import prisma from "@calcom/prisma";
import type { PracticeManagement } from "@calcom/prisma/client";
import type { RouterOutputs } from "@calcom/trpc/react";
import { trpc } from "@calcom/trpc/react";
import {
  Button,
  Dialog,
  Select,
  DialogContent,
  TextField,
  Tooltip,
  showToast,
  Label,
  TextAreaField,
} from "@calcom/ui";
import { FiSearch } from "@calcom/ui/components/icon";

import { getSession } from "@lib/auth";
import { DateRangeOptions, ReportUploadOptions } from "@lib/constant";
import { formatNumber, formatPhoneNumber } from "@lib/practice/helper";
import type { inferSSRProps } from "@lib/types/inferSSRProps";
import type { ReportFile, ReportRow } from "@lib/types/practice";

import DatePicker from "@components/DatePicker";
import Empty from "@components/Empty";
import { GreenCheckIcon, RedCheckIcon, NoneIcon } from "@components/Icons";
import { LiteralAvatar } from "@components/LiteralAvatar";
import WidgetSkeletonLoader from "@components/WidgetSkeletonLoader";
import SkeletonLoader from "@components/booking/SkeletonLoader";
import BlueChip from "@components/chip/BlueChip";
import GreenChip from "@components/chip/GreenChip";
import RedChip from "@components/chip/RedChip";
import YellowChip from "@components/chip/YellowChip";
import { getLayout } from "@components/layouts/MainLayout";
import { useOfficeContext } from "@components/navbar";
import PaymentForm from "@components/payment/PaymentForm";
import Pagination from "@components/ui/Pagination";

type Charge = RouterOutputs["viewer"]["payments"]["list"]["payments"][number];

type PracticeProps = inferSSRProps<typeof getServerSideProps>;

type UploadReportDialogProps = {
  open: boolean;
  handler: React.Dispatch<React.SetStateAction<boolean>>;
  officeId?: number;
  officeName?: string;
  practice?: PracticeManagement;
};

const filterOptions = [
  { label: "Transactions", value: "charges" },
  { label: "Outreach", value: "outreach" },
];

export const ViewPaymentLinkDialog = ({
  open,
  handler,
  paymentEnv,
  appId,
  offices,
  officeId,
}: UploadReportDialogProps & { paymentEnv: any; appId: any; offices: any }) => {
  const office = offices.find((office: { id: number | undefined }) => office.id === officeId);

  // useEffect(() => {
  //   console.log(`${process.env.NEXT_PUBLIC_WEBAPP_URL}/${office.name}/payment-link`)
  // }, officeId);

  const handleFinixPayment = async (token: string) => {
    // To Do
  };

  const copyLinktoClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    showToast("Copied to clipboard!", "success");
  };

  return (
    <div>
      <Dialog open={open} onOpenChange={handler}>
        <DialogContent className="z-0 max-h-[70vh] min-w-[1000px] px-0">
          <div className="-translate-y-36 rounded-md border border-gray-200 bg-white p-3">
            <div className="flex items-center justify-between">
              <div className="w-[88%]">
                <TextField
                  className="m-0 cursor-not-allowed bg-gray-100"
                  disabled
                  readOnly
                  value={`${process.env.NEXT_PUBLIC_WEBAPP_URL}/${office.officename}/payment-link`}
                />
              </div>
              <Button
                onClick={() =>
                  copyLinktoClipboard(
                    `${process.env.NEXT_PUBLIC_WEBAPP_URL}/${office.officename}/payment-link`
                  )
                }>
                Copy Link
              </Button>
            </div>
            <p className="mt-2 text-center text-sm leading-6 text-gray-600">
              Copy the payment link above to embed (add) to your office&apos;s website so patients can easily
              make payments online.
            </p>
          </div>
          <div className="h-[68vh] -translate-y-36 overflow-auto px-3">
            <div className="flex flex-col items-center justify-between gap-2 bg-white px-0 py-6 md:flex-row md:gap-0 md:px-8">
              <div className="flex w-full items-center justify-between">
                <div className="flex w-full flex-col justify-start gap-2 md:flex-row md:items-center md:justify-between md:gap-0">
                  <div className="rounded bg-white py-1.5 px-5 md:px-0">
                    <img src={office.logo} alt="logo" className="h-16 w-auto p-1 md:mx-auto" />
                  </div>
                  <div className="flex flex-col items-start gap-1 px-6 md:items-end md:p-0">
                    <p className="text-base font-bold capitalize text-gray-800">{office.name}</p>
                    <p className="text-sm leading-6 text-gray-600">{office.phone}</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ backgroundColor: office.headerColor ?? undefined }}
              className="flex items-center justify-between">
              <p className="py-3 px-6 text-sm text-white md:px-8">{office.address1 || office.address2}</p>
            </div>
            <div className="grid grid-cols-1 rounded-bl-md rounded-br-md border border-gray-200 md:grid-cols-7">
              <div className="col-span-4 flex flex-col gap-5 rounded-bl-md border-r border-gray-200 bg-white py-8 px-1 pb-[60px] text-sm md:px-8">
                <div className="rounded-md border border-gray-200 p-5 md:p-5 md:py-7">
                  <h2 className="mb-2 text-xl font-bold text-gray-800">
                    Welcome to Our Online Payment Form
                    {/* Hey {patient.firstName}! <br className="md:hidden" />
                      You have a balance of{" "}
                      <span className="text-green-500">${patient.balance.toFixed(2)}</span> */}
                  </h2>
                  <p className="text-sm leading-6 text-gray-600">
                    Please use patient information you have on file with us.
                  </p>

                  <div className="mt-8 grid grid-cols-2 gap-6 border-t pt-8">
                    <div className="col-span-2">
                      <Label>Amount</Label>
                      <div className="relative">
                        <span className="absolute z-10 flex h-9 items-center rounded-l-md border border-gray-300 bg-gray-100 py-[9px] px-3">
                          $
                        </span>
                        <TextField type="number" className="relative pl-10" label="" />
                      </div>
                    </div>
                    <div>
                      <Label>First Name</Label>
                      <TextField label="" />
                    </div>
                    <div>
                      <Label>Last Name</Label>
                      <TextField label="" />
                    </div>
                    <div>
                      <Label>Email Address</Label>
                      <TextField label="" />
                    </div>
                    <div>
                      <Label>Phone Number</Label>
                      <TextField label="" />
                    </div>
                    <div className="col-span-2">
                      <Label>Notes</Label>
                      <TextAreaField rows={5} label="" name="" />
                    </div>
                  </div>
                </div>

                <div className="rounded-md border border-gray-200 p-5 md:p-5 md:py-7">
                  <h2 className="mb-3 text-xl font-bold text-gray-800">Payment Details</h2>
                  <p className="text-sm leading-6 text-gray-600">
                    Enter your payment details below to make a payment.
                  </p>
                  {/* {paymentError && (
                <p className="mt-4 rounded-md bg-red-200 py-2 px-3 text-sm text-red-700">{paymentError}</p>
              )} */}
                  <div className="mt-6 flex flex-col items-center gap-4 border-t pt-4">
                    <PaymentForm
                      appId={appId}
                      env={paymentEnv}
                      buttonBackColor={office.buttonColor}
                      buttonText="Pay Balance"
                      handlePayment={handleFinixPayment}
                      // isPaymentProcessing={isPaymentProcessing}
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-3 flex flex-col rounded-br-md bg-[#FAFBFD] px-8 py-8">
                <div className="mb-6 rounded-md border border-gray-200 bg-white p-7">
                  <h2 className="mb-2 text-xl font-bold text-gray-800">Have a Question?</h2>
                  <p className="mb-7 text-sm leading-6 text-gray-600">
                    Something look off? or just have a question? No problem. Call or email via our details
                    below and we&apos;ll help out.
                  </p>
                  {office.email && (
                    <p className="mb-2 text-sm leading-6 text-gray-600">Email : {office.email || "N/A"}</p>
                  )}
                  {office.phone && (
                    <p className="text-sm leading-6 text-gray-600">Phone Number : {office.phone}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export const UploadReportDialog = ({
  open,
  handler,
  officeId,
  practice,
  officeName,
}: UploadReportDialogProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [reportType, setReportType] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [reportData, setReportData] = useState<Papa.ParseResult<ReportRow> | null>(null);
  const [reportFileList, setReportFileList] = useState<Array<ReportFile>>([]);
  const [isUploading, setUploading] = useState(false);
  const [result, setResult] = useState("");

  const { currentOffice } = useContext(officeContext);

  const [officeOption, setOfficeOption] = useState<any>();
  const [OfficeOptions, setOfficeOptions] = useState<{ value: number; label: string }[]>([]);

  const reportOptions = ReportUploadOptions.filter((option) => option.type == practice);

  const { data: offices } = trpc.viewer.office.get.useQuery({});

  useEffect(() => {
    if (offices) {
      setOfficeOptions([
        ...offices
          .filter((office) => office.status === OfficeStatus.ACTIVE || office.status === OfficeStatus.PAUSE)
          .map((office) => ({ value: office.id, label: office.name }))
          .sort((a, b) => (a.label > b.label ? 1 : -1)),
      ]);
    }
  }, [offices]);

  useEffect(() => {
    setOfficeOption(OfficeOptions.find((option) => option.value === currentOffice) || OfficeOptions[0]);
  }, [OfficeOptions]);

  const uploadMutation = trpc.viewer.report.upload.useMutation({
    onSuccess: async (result) => {
      setUploading(false);
      showToast("Report upload succeed", "success");
      console.log(result);
      setResult(JSON.stringify(result));
    },
    onError: (error) => {
      setUploading(false);
      showToast(error.message, "error");
      setResult(error.message);
    },
  });

  const addToUploadList = () => {
    if (!file || !reportData) return;

    reportFileList.push({
      type: reportType,
      data: [...reportData.data],
      file: file,
      status: {
        success: reportData.data.length,
        error: reportData.errors.length,
      },
    });
    setReportFileList([...reportFileList]);
    setFile(null);
    setReportData(null);
  };

  const removeFromList = (index: number) => {
    reportFileList.splice(index, 1);
    setReportFileList([...reportFileList]);
  };

  const readCSVReport = (file: File) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results: Papa.ParseResult<ReportRow>) {
        console.log(results);
        setReportData(results);
      },
    });
  };

  const uploadReports = async () => {
    if (!officeId) {
      alert("bug");
      return;
    }
    setUploading(true);
    uploadMutation.mutate({ officeId: officeId, reportData: reportFileList });
  };

  return (
    <Dialog open={open} onOpenChange={handler}>
      <DialogContent className="flex w-full !max-w-[750px] flex-col gap-5 bg-gray-50 p-5 text-gray-600">
        <div className="flex items-center justify-between rounded-md border border-gray-200 bg-white py-4 px-5">
          <p className="text-base font-bold text-gray-800">
            {officeName} ({practice})
          </p>
          <div className="w-full md:w-60">
            <Select
              instanceId="upload"
              placeholder="Select Report Type"
              options={reportOptions}
              value={reportOptions.find((c) => c.value === reportType)}
              onChange={(option) => option && setReportType(option.value)}
            />
          </div>
        </div>
        <div className="flex items-center justify-between rounded-md border border-gray-200 bg-white py-4 px-5">
          <p className="text-base font-bold text-gray-800">Select an office to upload a report</p>
          <div className="w-full md:w-60">
            <Select
              instanceId="office"
              options={OfficeOptions}
              value={officeOption}
              placeholder="Select an Office"
              onChange={(option) => {
                if (option) {
                  setOfficeOption(option);
                }
              }}
            />
          </div>
        </div>
        <div className="flex w-full items-center justify-center rounded-md border border-gray-200 bg-white p-10">
          <div className="flex flex-col items-center justify-center gap-6">
            <p className="text-base font-bold text-gray-800">Upload a Report</p>
            <p className="w-[350px] text-center text-sm leading-6 text-gray-500">
              Please upload a csv format of the report generated from the PMS system.
            </p>
            <Button className="w-32 items-center justify-center" onClick={() => inputRef.current?.click()}>
              Upload
            </Button>
            {file && <p className="text-xs text-gray-500">{file.name}</p>}
          </div>
          <TextField
            ref={inputRef}
            className="hidden"
            type="file"
            accept=".csv,.txt"
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              const files = e.target.files;

              if (files && files.length !== 0) {
                setFile(files[0]);
                readCSVReport(files[0]);
              }
            }}
          />
        </div>
        <div className="flex justify-end gap-4">
          <Button
            color="secondary"
            className="w-40 justify-center text-xs"
            disabled={!reportType.length || !file}
            onClick={addToUploadList}>
            Save & Add Another
          </Button>
          <Button
            className="w-40 justify-center text-xs"
            disabled={
              !reportFileList.length || !!reportFileList.reduce((acc, cur) => acc + cur.status.error, 0)
            }
            loading={isUploading}
            onClick={uploadReports}>
            Submit Report(s)
          </Button>
        </div>
        {reportFileList.length > 0 && <div className="border-b border-r-gray-200" />}
        {reportFileList.length > 0 && (
          <div className="space-y-2">
            {reportFileList.map((report, index) => (
              <div
                className={`flex items-center justify-between rounded-md border  py-2 px-5 ${
                  report.status.error > 0 ? "border-red-400 bg-red-200" : "border-gray-200 bg-white"
                }`}
                key={report.file.name}>
                <p className="text-sm capitalize text-gray-500">
                  <span className="font-bold">{report.type}</span> : {report.file.name} -{" "}
                  <span className="text-xs">Success </span>
                  <span className="font-bold text-green-900">{report.status.success}</span>,
                  <span className="text-xs"> Error </span>
                  <span className="font-bold text-red-900">{report.status.error}</span>
                  <span className="text-xs"> of Total </span>
                  <span className="font-bold">{report.status.success + report.status.error}</span>
                </p>
                <Button color="destructive" onClick={() => removeFromList(index)}>
                  Remove
                </Button>
              </div>
            ))}
          </div>
        )}
        {result && (
          <div className="flex items-center justify-between space-y-2 rounded-md border  py-2 px-5 ">
            {result}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default function Practice({ offices, user, reportLink, appId, paymentEnv }: PracticeProps) {
  const { t } = useLocale();

  const { currentOffice } = useOfficeContext();

  console.log("Current Office", currentOffice);

  const firstOffice = offices[0].id;

  const [animationParentRef] = useAutoAnimate<HTMLDivElement>();

  const OfficeOptions = offices
    .map((office) => ({ value: office.id, label: office.name }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [reportingDialogOpen, setReportingDialogOpen] = useState(false);
  const [paymentLinkDialogOpen, setPaymentLinkDialogOpen] = useState(false);

  const [pageNum, setPageNum] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");

  const [isMobile, setIsMobile] = useState(false);
  const [widgetCnt, setWidgetCnt] = useState(0);
  const [officeOption, setOfficeOption] = useState<any>();
  const [selectedOffice, setSelectedOffice] = useState<any>();
  const [selectedCharge, setSelectedCharge] = useState<Charge>();
  const [filterOption, setFilterOption] = useState(filterOptions[0]);
  const [selectedOfficeId, setSelectedOfficeId] = useState<number>(0);
  const [chargeDialogOpen, setChargeDialogOpen] = useState(false);

  const [startDate, setStartDate] = useState(dayjs().startOf("month").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [currentOption, setCurrentOption] = useState(DateRangeOptions[1]); // Set initial selected option
  const [isCustomDateRange, setCustomDateRange] = useState(false);

  const dateRangeOptions = isCustomDateRange
    ? [...DateRangeOptions, { value: 5, label: "Custom Date Range" }]
    : DateRangeOptions;

  useEffect(() => {
    if (!currentOffice && OfficeOptions.length) {
      setOfficeOption(OfficeOptions[0]);
      const selected = offices.find((o) => o.id === OfficeOptions[0].value);
      setSelectedOffice(selected);
    } else if (currentOffice) {
      setOfficeOption(OfficeOptions.find((office) => office.value === currentOffice));
      const selected = offices.find((o) => o.id === currentOffice);
      setSelectedOffice(selected);
    }
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
      setPageNum(0);
    }, 800);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  const handleDateRangeChange = (option: { label: string; value: number }) => {
    setCurrentOption(option);
    setCustomDateRange(false);

    switch (option.label) {
      case "All Time":
        setStartDate(dayjs("2023/02/02").startOf("year").format("YYYY-MM-DD"));
        setEndDate(dayjs().format("YYYY-MM-DD"));
        break;
      case "Month to Date":
        setStartDate(dayjs().startOf("month").format("YYYY-MM-DD"));
        setEndDate(dayjs().format("YYYY-MM-DD"));
        break;
      case "Today":
        setStartDate(dayjs().format("YYYY-MM-DD"));
        setEndDate(dayjs().format("YYYY-MM-DD"));
        break;
      case "Past 7 Days":
        setStartDate(dayjs().subtract(6, "day").format("YYYY-MM-DD"));
        setEndDate(dayjs().format("YYYY-MM-DD"));
        break;
      case "Last Month":
        setStartDate(dayjs().subtract(1, "month").startOf("month").format("YYYY-MM-DD"));
        setEndDate(dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"));
        break;
      default:
        break;
    }
  };

  const handleDatePickerChange = (start: string, end: string) => {
    setStartDate(start);
    setEndDate(end);
    setCurrentOption({ value: 5, label: "Custom Date Range" });
    setCustomDateRange(true);
  };

  const handleNext = () => {
    const width = window.innerWidth;

    if (width < 750) {
      setIsMobile(true);
      if (widgetCnt >= 7) return;
    } else {
      setIsMobile(false);
      if (widgetCnt >= 5) return;
    }
    setWidgetCnt(widgetCnt + 1);
  };

  const handlePrev = () => {
    if (widgetCnt <= 0) return;
    setWidgetCnt(widgetCnt - 1);
  };

  const { data: chargeData, isLoading: chargeLoading } = trpc.viewer.payments.list.useQuery(
    {
      billingOfficeId: Number(currentOffice),
      pageSize: perPage,
      pageNum,
      startDate: startDate,
      endDate: endDate,
    },
    {
      enabled: !!currentOffice,
    }
  );

  const { data: outreachData, isLoading: outreachLoading } = trpc.viewer.outreach.list.useQuery(
    {
      billingOfficeId: Number(currentOffice),
      pageSize: perPage,
      pageNum,
      type: undefined,
      startDate: startDate,
      endDate: endDate,
    },
    {
      enabled: !!currentOffice,
    }
  );

  const { data: metrics, isLoading: metricsLoading } = trpc.viewer.payments.getPracticeMetrics.useQuery(
    {
      billingOfficeId: Number(currentOffice),
    },
    {
      enabled: !!currentOffice,
    }
  );

  const { data: office } = trpc.viewer.office.getById.useQuery(
    {
      officeId: Number(currentOffice),
    },
    {
      enabled: !!currentOffice,
    }
  );

  return (
    <Shell
      title={t("practice")}
      heading={
        <Tooltip
          content={
            officeOption?.label || OfficeOptions.find((option) => option.value == currentOffice)?.label
          }
          className="bg-white text-gray-500">
          <div className="">
            <span className="line-clamp-1 max-w-[500px] overflow-ellipsis whitespace-normal break-words">
              Practice Home:{" "}
              {officeOption?.label || OfficeOptions.find((option) => option.value == currentOffice)?.label}
            </span>
          </div>
        </Tooltip>
      }
      subtitle={
        <p className="mt-2">
          Practice Management System: {office?.practice ? formatPMS(office?.practice) : "N/A"}
        </p>
      }>
      <div className="mb-4 flex flex-col gap-4 md:hidden md:flex-row">
        {selectedOffice?.showUploadButton && (
          <Button
            color="tertiary"
            className="flex justify-center"
            onClick={() => {
              setDialogOpen(true);
            }}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.5 5.90234C13.0225 5.91082 13.847 5.97834 14.3849 6.51621C15 7.1313 15 8.12124 15 10.1011V10.8011C15 12.781 15 13.771 14.3849 14.386C13.7698 15.0011 12.7799 15.0011 10.8 15.0011H5.2C3.2201 15.0011 2.23015 15.0011 1.61508 14.386C1 13.771 1 12.781 1 10.8011V10.1011C1 8.12124 1 7.1313 1.61508 6.5162C2.15294 5.97834 2.97748 5.91082 4.5 5.90234"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M7.99844 10.1V1M7.99844 1L10.0984 3.45M7.99844 1L5.89844 3.45"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Button>
        )}
      </div>

      <div className="mb-4">
        <div className="flex w-full flex-col items-end justify-between gap-4 md:flex-row">
          <div className="relative w-full md:w-72">
            <TextField
              className="mb-0 border border-gray-200 pl-8"
              placeholder="Patient Name or ID"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <FiSearch className="absolute top-2.5 left-2 h-4 w-4 cursor-pointer text-gray-500" />
          </div>
          <div className="w-full md:w-44">
            <Select
              instanceId="filter"
              options={filterOptions}
              value={filterOption}
              onChange={(option) => {
                if (option) {
                  setFilterOption(option);
                  setPageNum(0);
                }
              }}
            />
          </div>
          <div className="relative w-full md:w-64">
            <Select
              instanceId="date"
              options={dateRangeOptions}
              value={currentOption}
              onChange={(option) => option && handleDateRangeChange(option)}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: "rgb(229 231 235)",
                  paddingLeft: "28px",
                }),
              }}
            />
            <div className="pointer-events-none absolute inset-0 right-auto flex items-center">
              <svg
                className="ml-3 h-4 w-4 fill-current text-slate-500 dark:text-slate-400"
                viewBox="0 0 16 16">
                <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
              </svg>
            </div>
          </div>

          <div className="flex w-full gap-4 md:w-auto md:flex-row md:justify-end">
            <div className="w-full md:w-64">
              <DatePicker
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                startDate={startDate}
                endDate={endDate}
                onChange={handleDatePickerChange}
              />
            </div>
            <div className="flex gap-2">
              <Button
                className="rounded-full border border-gray-200 bg-white"
                color="minimal"
                onClick={handlePrev}
                disabled={widgetCnt === 0}>
                {"<"}
              </Button>
              <Button
                className="rounded-full border border-gray-200 bg-white"
                color="minimal"
                onClick={handleNext}
                disabled={isMobile ? widgetCnt === 4 : widgetCnt === 1}>
                {">"}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-4 flex w-full gap-4 overflow-hidden">
        <div
          className={classNames(
            "flex w-full gap-4 transition-all",
            widgetCnt === 0
              ? "translate-x-0"
              : widgetCnt === 1
              ? "-translate-x-[103%] md:-translate-x-[33.6%]"
              : widgetCnt === 2
              ? "-translate-x-[206%] md:-translate-x-[66.6%]"
              : widgetCnt === 3
              ? "-translate-x-[309%] md:-translate-x-[100%]"
              : widgetCnt === 4
              ? "-translate-x-[412%] md:-translate-x-[133.3%]"
              : widgetCnt === 5
              ? "-translate-x-[515%] md:-translate-x-[166.6%]"
              : "-translate-x-[618%] md:-translate-x-[200%]"
          )}>
          {/* Total A/R */}
          <div className="relative min-w-[100%] overflow-hidden rounded-md border border-gray-200 bg-white p-5 md:min-w-[32.33%]">
            {metricsLoading ? (
              <WidgetSkeletonLoader />
            ) : (
              <>
                <div className="flex w-full items-center text-left text-sm text-gray-500">
                  <Tooltip
                    content={
                      "$" + formatNumber(metrics?.outstandingAmount, "number").toString().split(".")[0]
                    }
                    className="bg-white text-gray-600">
                    <span className="line-clamp-1 mr-3 max-w-[236px] break-words text-[28px] font-bold leading-7 text-black">
                      ${formatNumber(metrics?.outstandingAmount, "balance").toString().split(".")[0]}
                    </span>
                  </Tooltip>
                  <div>
                    <Tooltip className="bg-white text-gray-600" content="Total A/R">
                      <p className="line-clamp-1 text-base font-bold text-black">Total A/R</p>
                    </Tooltip>
                    <p>All Time</p>
                  </div>
                </div>
                <div className="my-6 border-b border-gray-200" />
                <p className="text-sm text-gray-500">
                  This is the total dollar amount outstanding across all patients at this location.
                </p>
              </>
            )}
          </div>

          {/* Gross Volume */}
          <div className="relative min-w-[100%] overflow-hidden rounded-md border border-gray-200 bg-white p-5 md:min-w-[32.33%]">
            {metricsLoading ? (
              <WidgetSkeletonLoader />
            ) : (
              <>
                <div className="flex w-full items-center text-left text-sm text-gray-500">
                  <Tooltip
                    content={"$" + formatNumber(metrics?.totalPayments, "number").toString().split(".")[0]}
                    className="bg-white text-gray-600">
                    <span className="line-clamp-1 mr-3 max-w-[199px] break-words text-[28px] font-bold leading-7 text-black">
                      ${formatNumber(metrics?.totalPayments, "balance").toString().split(".")[0]}
                    </span>
                  </Tooltip>
                  <div>
                    <Tooltip className="bg-white text-gray-600" content="Gross Volume">
                      <p className="line-clamp-1 text-base font-bold text-black">Gross Volume</p>
                    </Tooltip>
                    <p>All Time</p>
                  </div>
                </div>
                <div className="my-6 border-b border-gray-200" />
                <p className="text-sm text-gray-500">
                  Estimated revenue from all types of payments at this location.
                </p>
              </>
            )}
          </div>

          {/* Net Volume */}
          <div className="relative min-w-[100%] overflow-hidden rounded-md border border-gray-200 bg-white p-5 md:min-w-[32.33%]">
            {metricsLoading ? (
              <WidgetSkeletonLoader />
            ) : (
              <>
                <div className="flex w-full items-center text-left text-sm text-gray-500">
                  <Tooltip
                    content={"$" + formatNumber(metrics?.netPayments, "number").toString().split(".")[0]}
                    className="bg-white text-gray-600">
                    <span className="line-clamp-1 mr-3 max-w-[217px] break-words text-[28px] font-bold leading-7 text-black">
                      ${formatNumber(metrics?.netPayments, "balance").toString().split(".")[0]}
                    </span>
                  </Tooltip>
                  <div>
                    <Tooltip className="bg-white text-gray-600" content="Net Volume">
                      <p className="line-clamp-1 text-base font-bold text-black">Net Volume</p>
                    </Tooltip>
                    <p>All Time</p>
                  </div>
                </div>
                <div className="my-6 border-b border-gray-200" />
                <p className="text-sm text-gray-500">
                  Estimated revenue from payments after fees, refunds and disputes at this location.
                </p>
              </>
            )}
          </div>

          {/* Total Outreach */}
          <div className="relative min-w-[100%] overflow-hidden rounded-md border border-gray-200 bg-white p-5 md:min-w-[32.33%]">
            {metricsLoading ? (
              <WidgetSkeletonLoader />
            ) : (
              <>
                <div className="flex w-full items-center text-left text-sm text-gray-500">
                  <Tooltip
                    content={formatNumber(metrics?.totalNotifications, "number").toString().split(".")[0]}
                    className="bg-white text-gray-600">
                    <span className="line-clamp-1 mr-3 max-w-[193px] break-words text-[28px] font-bold leading-7 text-black">
                      {formatNumber(metrics?.totalNotifications, "number").toString().split(".")[0]}
                    </span>
                  </Tooltip>
                  <div>
                    <Tooltip className="bg-white text-gray-600" content="Total Outreach">
                      <p className="line-clamp-1 text-base font-bold text-black">Total Outreach</p>
                    </Tooltip>
                    <p>All Time</p>
                  </div>
                </div>
                <div className="my-6 border-b border-gray-200" />
                <p className="text-sm text-gray-500">
                  The total outreach sent out, both automated and manual.{" "}
                  <span className="font-bold text-black">
                    {formatNumber(metrics?.smsNotifications, "number")}
                  </span>{" "}
                  Texts,{" "}
                  <span className="font-bold text-black">
                    {formatNumber(metrics?.emailNotifications, "number")}
                  </span>{" "}
                  Emails.
                </p>
              </>
            )}
          </div>
        </div>
      </div>

      <>
        {filterOption.value === "charges" ? (
          <div ref={animationParentRef}>
            {!chargeLoading && !!chargeData?.payments?.length && (
              <div>
                <div className="bg-white xl:pt-0">
                  <div className="min-h-[650px] overflow-x-auto rounded-md border border-gray-200 bg-white">
                    <table className="w-full max-w-full table-auto">
                      <thead>
                        <tr>
                          <th className="border-b bg-gray-50 p-3 text-left text-sm text-gray-500">Patient</th>
                          <th className="border-b bg-gray-50 p-3 text-left text-sm text-gray-500">
                            Paid Date
                          </th>
                          <th className="border-b bg-gray-50 p-3 text-left text-sm text-gray-500">Amount</th>
                          <th className="border-b bg-gray-50 p-3 text-left text-sm text-gray-500">Fees</th>
                          <th className="border-b bg-gray-50 p-3 text-left text-sm text-gray-500">
                            Net Deposit
                          </th>
                          <th className="border-b bg-gray-50 p-3 text-left text-sm text-gray-500">
                            Recorded
                          </th>
                          <th className="border-b bg-gray-50 p-3 text-left text-sm text-gray-500">Source</th>
                          <th className="border-b bg-gray-50 p-3 text-left text-sm text-gray-500">Status</th>
                        </tr>
                      </thead>

                      <tbody className="divide-y divide-gray-200 bg-white" data-testid="appointments">
                        {chargeData?.payments.length &&
                          chargeData?.payments.map((item, key) => (
                            <tr
                              className="cursor-pointer text-sm even:bg-gray-50 hover:bg-gray-100"
                              key={key}
                              onClick={() => {
                                setSelectedCharge(item);
                                setChargeDialogOpen(true);
                                setSelectedOfficeId(item.patient?.billingOffice?.id || 0);
                              }}>
                              <td
                                className="w-[200px] min-w-[200px] p-3 text-gray-600"
                                onClick={(e) => e.stopPropagation()}>
                                <Link
                                  href={`/patients/${item.patient?.id}`}
                                  className={!item.patient ? "pointer-events-none" : ""}>
                                  <Tooltip
                                    content={
                                      <div className="space-y-1">
                                        <p>
                                          Name:{" "}
                                          {item.patient
                                            ? item.patient?.firstName + " " + item.patient?.lastName
                                            : "Unable to Match"}
                                        </p>
                                        <p>PID: {item.patient?.foreignId}</p>
                                        {item.patient?.email && <p>Email: {item.patient?.email}</p>}
                                        {item.patient?.phoneNumber && (
                                          <p>Phone: {formatPhoneNumber(item.patient?.phoneNumber)}</p>
                                        )}
                                        <p>DOB: {dayjs(item.patient?.birthday).format("DD/MM/YYYY")}</p>
                                        <p>Age: {dayjs().diff(item.patient?.birthday, "year")}</p>
                                      </div>
                                    }
                                    className="bg-white text-gray-600">
                                    <div className="flex items-center gap-2">
                                      <LiteralAvatar
                                        fullName={item.patient?.firstName + " " + item.patient?.lastName}
                                      />
                                      <div>
                                        <p className="line-clamp-1 w-[150px]">
                                          {item.patient
                                            ? item.patient?.firstName + " " + item.patient?.lastName
                                            : "Unable to Match"}
                                        </p>
                                        <p className="text-xs">PID: {item.patient?.foreignId}</p>
                                      </div>
                                    </div>
                                  </Tooltip>
                                </Link>
                              </td>
                              <td className="w-[150px] p-3 text-gray-600">
                                <Tooltip
                                  content={
                                    <p>
                                      Deposit Date:
                                      {item.type === "PROMPTED"
                                        ? "N/A"
                                        : dayjs(item.depositDate)
                                            .tz(selectedOffice?.timeZone)
                                            .format("MM/DD/YYYY")}
                                    </p>
                                  }
                                  className="bg-white text-gray-600">
                                  <p>{dayjs(item.date).tz(selectedOffice?.timeZone).format("MM/DD/YYYY")}</p>
                                </Tooltip>
                              </td>
                              <td className="w-[150px] p-3 text-gray-600">
                                ${formatNumber(item.amount, "balance")}
                              </td>
                              <td className="w-[150px] p-3 text-gray-600">
                                {item.type === "PROMPTED" ? "N/A" : "$" + Number(item.fee).toFixed(2)}
                              </td>
                              <td className="w-[150px] p-3 text-gray-600">
                                ${(item.amount - item.fee).toFixed(2)}
                              </td>
                              <td className="w-[120px] p-3 text-gray-600">
                                {item.type === PaymentType.PROCESSED &&
                                  item.transactionStatus !== TransactionStatus.FAILED &&
                                  (item.recorded ? <GreenCheckIcon /> : <RedCheckIcon />)}
                                {(item.type === PaymentType.PROMPTED ||
                                  item.transactionStatus === TransactionStatus.FAILED) && <NoneIcon />}
                              </td>
                              <td className="w-[170px] p-3 text-sm text-gray-600">
                                {item.source === PaymentSource.AUTOMATEDAR ? (
                                  <Tooltip content={item.type} className="bg-white text-gray-500">
                                    <p>Automated A/R</p>
                                  </Tooltip>
                                ) : item.source === PaymentSource.MEMBERSHIP ? (
                                  "Membership"
                                ) : item.source === PaymentSource.ONLINE ? (
                                  "Online Payment"
                                ) : item.source === PaymentSource.PAYMENTPLAN ? (
                                  "Payment Plan"
                                ) : item.source === PaymentSource.TERMINAL ? (
                                  "Terminal Payment"
                                ) : item.source === PaymentSource.VIRTUAL ? (
                                  "Virtual Payment"
                                ) : (
                                  "N/A"
                                )}
                              </td>
                              <td className="w-[150px] p-3 text-sm text-gray-600">
                                {item.transactionStatus === TransactionStatus.SUCCESSFUL &&
                                  item.type === PaymentType.PROCESSED && (
                                    <GreenChip text={item.transactionStatus} />
                                  )}
                                {item.transactionStatus === TransactionStatus.FAILED && (
                                  <RedChip text={item.transactionStatus} />
                                )}
                                {item.transactionStatus === TransactionStatus.REFUND && (
                                  <YellowChip text="REFUNDED" />
                                )}
                                {item.type === PaymentType.PROMPTED && <BlueChip text="PROMPTED" />}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <Pagination
                  page={pageNum}
                  count={chargeData?.count}
                  perPage={perPage}
                  handleNext={(page) => {
                    setPageNum(page);
                  }}
                  handlePrev={(page) => {
                    setPageNum(page);
                  }}
                  handleRowsPerPage={(rows) => {
                    setPageNum(0);
                    setPerPage(rows);
                  }}
                />
              </div>
            )}
            {chargeLoading && <SkeletonLoader />}
            {!chargeLoading && Number(chargeData?.payments?.length) === 0 && (
              <div className="flex justify-center rounded-md border border-gray-200 bg-white py-10">
                <div className="w-[450px]">
                  <Empty
                    title="No Data"
                    description="We currently have no recent transactions associated with your account. If you believe this is an error, please contact your account manager for assistance"
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          <div ref={animationParentRef}>
            {!outreachLoading && Number(outreachData?.msgLogs.length) > 0 && (
              <div>
                <div className="min-h-[650px] overflow-x-auto rounded-md border border-gray-200 bg-white">
                  <table className="w-full max-w-full table-auto">
                    <thead>
                      <tr>
                        <th className="border-b bg-gray-50 p-3 text-left text-sm text-gray-500">Patient</th>
                        <th className="border-b bg-gray-50 p-3 text-left text-sm text-gray-500">Template</th>
                        <th className="border-b bg-gray-50 p-3 text-left text-sm text-gray-500">Type</th>
                        <th className="border-b bg-gray-50 p-3 text-left text-sm text-gray-500">Sent</th>
                        <th className="border-b bg-gray-50 p-3 text-left text-sm text-gray-500">Status</th>
                      </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200 bg-white text-sm" data-testid="appointments">
                      {outreachData?.msgLogs.map((item, key) => (
                        <tr className="cursor-pointer even:bg-gray-50 hover:bg-gray-100" key={key}>
                          <td className="w-[200px] min-w-[200px] p-3 text-sm text-gray-600">
                            <Link href={`/patients/${item?.pid}`}>
                              <div className="flex items-center gap-2">
                                <LiteralAvatar fullName="Test User" />
                                <div>
                                  {item.patientName}
                                  <p className="text-xs">PID: {item.patientId}</p>
                                </div>
                              </div>
                            </Link>
                          </td>
                          <td className="w-[300px] min-w-[200px] p-3 text-gray-600">{item.template}</td>
                          <td className="w-[200px] min-w-[100px] p-3 text-gray-600">{item.type}</td>
                          <td className="w-[200px] min-w-[150px] p-3 text-gray-600">
                            {dayjs(item.sentDate)
                              .tz(selectedOffice?.timeZone as string)
                              .format("MM/DD/YYYY")}
                          </td>
                          <td className="w-[100px] min-w-[100px] p-3 text-gray-600">
                            <GreenCheckIcon />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  page={pageNum}
                  count={outreachData?.count || 0}
                  perPage={perPage}
                  handleNext={(page) => {
                    setPageNum(page);
                  }}
                  handlePrev={(page) => {
                    setPageNum(page);
                  }}
                  handleRowsPerPage={(rows) => {
                    setPageNum(0);
                    setPerPage(rows);
                  }}
                />
              </div>
            )}
            {outreachLoading && <SkeletonLoader />}
            {!outreachLoading && Number(outreachData?.msgLogs.length) === 0 && (
              <div className="flex justify-center rounded-md border border-gray-200 bg-white py-10">
                <div className="w-[450px]">
                  <Empty
                    title="No Data"
                    description="We currently have no recent outreach data from your account. If you believe this is an error, please contact your account manager for assistance."
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </>
      {selectedCharge && (
        <ChargeDialog
          open={chargeDialogOpen}
          handler={setChargeDialogOpen}
          timezone={selectedOffice?.timeZone as string}
          charge={selectedCharge as Charge}
          officeId={selectedOfficeId}
        />
      )}
      {dialogOpen && (
        <UploadReportDialog
          open={dialogOpen}
          handler={setDialogOpen}
          officeId={officeOption?.value}
          officeName={officeOption?.label}
          practice={selectedOffice.practice}
        />
      )}
      <ReportDialog
        open={reportingDialogOpen}
        handler={setReportingDialogOpen}
        offices={offices}
        role={user?.role}
      />
      {officeOption?.value && (
        <ViewPaymentLinkDialog
          open={paymentLinkDialogOpen}
          handler={setPaymentLinkDialogOpen}
          paymentEnv={paymentEnv}
          officeId={officeOption?.value}
          offices={offices}
          appId={appId}
        />
      )}
    </Shell>
  );
}

Practice.getLayout = getLayout;

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const { req } = context;
  const session = await getSession({ req });

  if (!session?.user?.id) {
    return {
      redirect: {
        permanent: false,
        destination: "/auth/login",
      },
    };
  }

  const user = await prisma.user.findUnique({
    where: {
      id: session.user.id,
    },
    select: {
      id: true,
      currentOffice: true,
      groupId: true,
      role: true,
    },
  });

  if (!user) return { notFound: true };

  const officeIds = await getOfficeIdsByUserRole(user.id, user.role);

  const offices = await prisma.billingOffice.findMany({
    where: {
      id: {
        in: officeIds,
      },
      status: {
        in: [OfficeStatus.ACTIVE, OfficeStatus.PAUSE],
      },
      groupId: user.role == UserPermissionRole.ADMIN ? undefined : user.groupId,
    },
    select: {
      officename: true,
      id: true,
      name: true,
      practice: true,
      isNexHealthIntegrated: true,
      showUploadButton: true,
      status: true,
      email: true,
      phone: true,
      headerColor: true,
      logo: true,
      address1: true,
      address2: true,
      buttonColor: true,
    },
  });

  const currentOffice = await prisma.billingOffice.findUnique({
    where: {
      id: Number(user?.currentOffice),
    },
    select: {
      officename: true,
      id: true,
      name: true,
      practice: true,
      isNexHealthIntegrated: true,
    },
  });

  const reportLink = `${process.env.REPORT_ENGINE}/report?api-key=${process.env.REPORT_ENGINE_API_KEY}`;
  const env = process.env.FINIX_ENVIRONMENT;
  const appId = process.env.FINIX_APPLICATION_ID;
  return {
    props: {
      appId,
      paymentEnv: env,
      offices,
      reportLink,
      currentOffice,
      user,
    },
  };
};
