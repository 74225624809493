import React, { useState } from "react";

import {
  Dropdown,
  DropdownItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@calcom/ui";
import { BellIcon, HelpIcon, NoteIcon, UserIcon } from "@calcom/web/components/Icons";
import { ReportDialog } from "@calcom/web/components/dialog/ReportDialog";

import OfficeSelect from "./OfficeSelect";
import Search from "./Search";

const Profile = () => {
  return (
    <Dropdown modal={false}>
      <DropdownMenuTrigger asChild data-testid="event-type-options-">
        <div className="flex h-10 w-10 items-center justify-center rounded-md border border-gray-200 p-2">
          <UserIcon />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        style={{
          marginTop: "10px",
          width: "150px",
        }}>
        <DropdownMenuItem>
          <DropdownItem type="button" data-testid="event-type-edit-">
            Edit Profile
          </DropdownItem>
        </DropdownMenuItem>
        <DropdownMenuItem>
          <DropdownItem type="button" data-testid="event-type-edit-">
            Log Out
          </DropdownItem>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </Dropdown>
  );
};

function TopNavbar() {
  const [reportDlgOpen, setReportDlgOpen] = useState(false);

  return (
    <div className="fixed top-0 z-10 w-full border-b border-gray-200 bg-white py-1">
      <div className="mx-auto w-full max-w-[1440px] flex-col items-center gap-4 p-2 md:flex md:flex-row md:gap-0 md:p-0 md:pr-6">
        <div className="mb-4 flex gap-4 md:mb-0 md:flex-row">
          <div className="w-52 py-2">
            <img className="ml-6 h-9 w-auto" alt="logo" src="/logos/app.svg" />
          </div>
          <div className="flex-grow" />
          <div className="flex items-center gap-4 md:hidden">
            <div
              className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-md border border-gray-200 p-2"
              onClick={() => {
                setReportDlgOpen(true);
              }}>
              <NoteIcon />
            </div>
            <div className="flex h-10 w-10 items-center justify-center rounded-md border border-gray-200 p-2">
              <HelpIcon />
            </div>
            <div className="flex h-10 w-10 items-center justify-center rounded-md border border-gray-200 p-2">
              <BellIcon />
            </div>
            <Profile />
          </div>
        </div>
        <div className="mb-4 w-full md:ml-12 md:mb-0 md:w-auto">
          <Search />
        </div>
        <div className="hidden flex-grow md:block" />
        <div className="mb-2 flex justify-around gap-8 md:mr-8 md:mb-0 md:justify-start">
          <OfficeSelect />
          {/* <AddNewOffice />
          <AddTransaction /> */}
        </div>
        <div className="hidden items-center gap-4 md:flex">
          <div
            className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-md border border-gray-200 p-2"
            onClick={() => {
              setReportDlgOpen(true);
            }}>
            <NoteIcon />
          </div>
          <div className="flex h-10 w-10 items-center justify-center rounded-md border border-gray-200 p-2">
            <HelpIcon />
          </div>
          <div className="flex h-10 w-10 items-center justify-center rounded-md border border-gray-200 p-1">
            <BellIcon />
          </div>
          <Profile />
        </div>
      </div>
      <ReportDialog open={reportDlgOpen} handler={setReportDlgOpen} />
    </div>
  );
}

export default TopNavbar;
