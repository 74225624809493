import { useAutoAnimate } from "@formkit/auto-animate/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { UserPermissionRole } from "@prisma/client";
import type { GetServerSidePropsContext } from "next";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import dayjs from "@calcom/dayjs";
import Shell from "@calcom/features/shell/Shell";
import { useLocale } from "@calcom/lib/hooks/useLocale";
import prisma from "@calcom/prisma";
import { trpc } from "@calcom/trpc/react";
import type { MultiSelectCheckboxesOptionType } from "@calcom/ui";
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  Form,
  Label,
  MultiSelectCheckbox,
  Select,
  Tooltip,
} from "@calcom/ui";

import { getSession } from "@lib/auth";
import type { inferSSRProps } from "@lib/types/inferSSRProps";

import { LiteralAvatar } from "@components/LiteralAvatar";
import Pagination from "@components/ui/Pagination";

import { ssrInit } from "@server/lib/ssr";

type ChargesProps = inferSSRProps<typeof getServerSideProps>;

type ReportDialogProps = {
  open: boolean;
  handler: React.Dispatch<React.SetStateAction<boolean>>;
  offices: any;
  role: UserPermissionRole | undefined;
};

export type FormValues = {
  name: string;
  email: string;
  password: string;
};

const reportOptions = [{ value: "Billing & AR Report", label: "Billing & AR Report" }];

export const ReportDialog = ({ open, handler, offices, role }: ReportDialogProps) => {
  const utils = trpc.useContext();

  const [loading, setLoading] = useState<boolean>(false);

  const OfficeOptions = offices
    .map((office: { id: any; name: any }) => ({ value: office.id, label: office.name }))
    .sort(({ a, b }) => (a?.label > b?.label ? 1 : -1));

  const [selectedOfficeForTransaction, setSelectedOfficeForTransaction] = useState<
    MultiSelectCheckboxesOptionType[]
  >([]);
  const form = useForm<FormValues>({
    defaultValues: {
      name: "",
      email: "",
      password: "",
    },
    resolver: zodResolver(
      z.object({
        name: z.string().min(1, "Required"),
        email: z.string().min(1, "Required").email("Email type is not valid"),
        password: z.string().min(1, "Required"),
      })
    ),
  });

  const { register } = form;

  const submitValues = (values: FormValues) => {
    setLoading(true);
    // createSpecialistMutation.mutate(values);
  };

  return (
    <Dialog open={open} onOpenChange={handler}>
      <DialogContent className="flex flex-col gap-4 bg-gray-50 text-gray-600">
        <DialogHeader title="Generate Report" />
        <Form form={form} handleSubmit={submitValues}>
          <div className="space-y-6">
            <div>
              <Label>Select Report</Label>
              <Select options={reportOptions} />
            </div>
            {role === UserPermissionRole.GROUP_ADMIN && (
              <div className="md:block">
                <Label>Select Offices</Label>
                <MultiSelectCheckbox
                  className="w-full"
                  instanceId="office"
                  isMulti
                  options={OfficeOptions}
                  // onChange={(option) => {
                  //   if (option) {
                  //     changeCurrentOffice.mutate({ officeId: option.value });
                  //     setOfficeOption(option);
                  //   }
                  // }}
                  placeholder="Select offices"
                  setSelected={setSelectedOfficeForTransaction}
                  selected={selectedOfficeForTransaction}
                  setValue={setSelectedOfficeForTransaction}
                />
              </div>
            )}
            {/* <div className="my-5 border-b border-gray-200" /> */}
            <div className="flex justify-end gap-5">
              <Button color="minimal">Cancel</Button>
              <Button type="submit" loading={loading}>
                Run Report
              </Button>
            </div>
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default function Charges({ offices, user }: ChargesProps) {
  const { t } = useLocale();

  const [animationParentRef] = useAutoAnimate<HTMLDivElement>();

  const [pageNum, setPageNum] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
      setPageNum(0);
    }, 800);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  return (
    <Shell
      title={t("reporting")}
      heading={t("reporting")}
      subtitle={
        <p className="mt-2 max-w-[750px] leading-6">
          Generate and manage reports across all or specific locations connected to your account.
        </p>
      }
      CTA={
        <div className="hidden flex-col gap-4 md:flex md:flex-row">
          <Button
            className="block py-1 text-center"
            color="tertiary"
            onClick={async () => {
              setDialogOpen(true);
            }}>
            Generate Report
          </Button>
        </div>
      }>
      <div className="flex w-full flex-col" ref={animationParentRef}>
        {/* {!isLoading && !!data?.specialists?.length && ( */}
        <div>
          <div className="min-h-[650px] overflow-x-auto rounded-md border border-gray-200 bg-white">
            <table className="w-full max-w-full table-auto">
              <thead>
                <tr>
                  <th className="border-b bg-gray-50 p-3 text-left text-sm text-gray-500">Report Type</th>
                  <th className="border-b bg-gray-50 p-3 text-left text-sm text-gray-500">Date</th>
                  <th className="border-b bg-gray-50 p-3 text-left text-sm text-gray-500">Office(s)</th>
                  <th className="border-b bg-gray-50 p-3 text-left text-sm text-gray-500">Download</th>
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-200 bg-white" data-testid="appointments">
                {/* {data?.specialists &&
                  !!data?.specialists.length && */}
                {/* data?.specialists?.map((item, key) => ( */}
                {Array(5)
                  .fill(null)
                  .map((item, key) => (
                    <tr className="cursor-pointer text-sm even:bg-gray-50 hover:bg-gray-100" key={key}>
                      <td className="w-[250px] min-w-[200px] p-3 text-gray-600">
                        <div className="flex items-center gap-2">
                          <LiteralAvatar fullName={item?.name || "TEST USER"} />
                          <Tooltip
                            className="bg-white text-gray-600"
                            content={
                              <div className="space-y-1">
                                <p>{item?.name || "TEST TYPE"}</p>
                              </div>
                            }>
                            <div className="space-y-1">
                              <p className="line-clamp-1 w-[150px]">{item?.name || "TEST TYPE"}</p>
                            </div>
                          </Tooltip>
                        </div>
                      </td>
                      <td className="w-[150px] p-3 text-gray-600">
                        <div>
                          <p className="line-clamp-1 w-[150px]">
                            {dayjs(item?.createdDate).format("MM/DD/YYYY")}
                          </p>
                          <p className="text-xs">{dayjs(item?.createdDate).format("hh:mm A")}</p>
                        </div>
                      </td>
                      <td className="w-[500px] min-w-[150px] p-3 text-gray-600">
                        <p className="">A Office, B Office, C Office, D Office, E Office, and 4 more</p>
                      </td>
                      <td className="w-[150px] min-w-[150px] p-3 text-gray-600">
                        <Button className="bg-brand-primary">Download</Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <Pagination
            page={pageNum}
            count={0}
            // count={data?.count || 0}
            perPage={perPage}
            handleNext={(page) => {
              setPageNum(page);
            }}
            handlePrev={(page) => {
              setPageNum(page);
            }}
            handleRowsPerPage={(rows) => {
              setPageNum(0);
              setPerPage(rows);
            }}
          />
        </div>
        {/* )} */}
        {/* {isLoading && <SkeletonLoader />} */}
        {/* {!isLoading && !data?.specialists?.length && (
        <div className="flex justify-center rounded-md border border-gray-200 bg-white py-10">
          <div className="w-[450px]">
            <Empty
              title="No Data"
              description="There are currently no charges for this office. If you have any questions, please contact your Caresuite team member for assistance."
            />
          </div>
        </div>
        )} */}
      </div>

      <ReportDialog open={dialogOpen} handler={setDialogOpen} offices={offices} role={user?.role} />
    </Shell>
  );
}

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const { req } = context;
  const session = await getSession({ req });
  const ssr = await ssrInit(context);

  if (!session?.user?.id) {
    return {
      redirect: {
        permanent: false,
        destination: "/auth/login",
      },
    };
  }

  const user = await prisma.user.findUnique({
    where: {
      id: session.user.id,
    },
    select: {
      currentOffice: true,
      role: true,
    },
  });

  // const billingOffice = await prisma.billingOffice.findFirst({
  //   where: {
  //     id: Number(user?.currentOffice),
  //   },
  //   select: {
  //     timeZone: true,
  //     id: true,
  //   },
  // });

  const offices = await prisma.billingOffice.findMany({
    where: {
      userId: session.user.id,
    },
    select: {
      officename: true,
      id: true,
      name: true,
    },
  });

  // if (!billingOffice) return { notFound: true };

  // const reportLink = `${process.env.REPORT_ENGINE}/report?officeId=${billingOffice.id}&api-key=${process.env.REPORT_ENGINE_API_KEY}`;

  return {
    props: {
      // billingOffice,
      // reportLink,
      // currentOffice: user?.currentOffice,
      offices,
      user,
    },
  };
};
