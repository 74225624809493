import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import { trpc } from "@calcom/trpc/react";
import { Select } from "@calcom/ui";

import { useOfficeContext } from "./OfficeProvider";

const groupViewLinks: string[] = ["/home", "/transactions", "/insights", "users"];

export default function OfficeSelect() {
  const router = useRouter();
  const { currentOffice, setCurrentOffice } = useOfficeContext();
  const [officeOptions, setOfficeOptions] = useState<{ value: number; label: string }[]>([]);

  const { data: officeData } = trpc.viewer.office.list.useQuery();

  useEffect(() => {
    if (officeData) {
      setOfficeOptions(officeData);
      console.log("SET CURRENT OFFICE", officeData, currentOffice || officeData[0].value);
      setCurrentOffice(currentOffice || officeData[0].value);
    }
  }, [officeData]);

  return (
    <div className="block w-full md:w-[300px]">
      <Select
        instanceId="office"
        options={officeOptions}
        value={
          groupViewLinks.includes(router.asPath.split("?")[0])
            ? undefined
            : officeOptions.find((option) => option.value == currentOffice)
        }
        placeholder="Select an Office"
        onChange={(option) => {
          if (option) {
            // changeCurrentOffice.mutateAsync({ officeId: option.value });
            // setOfficeOption(option);
            setCurrentOffice(option.value);
            if (groupViewLinks.includes(router.asPath.split("?")[0])) router.push(`/practice`);
          }
        }}
      />
    </div>
  );
}
