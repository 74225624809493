import React from "react";

export default function GreenChip({ text, className }: { text: string; className?: string }) {
  return (
    <span
      className={`flex min-w-[100px] max-w-[140px] justify-center rounded-md bg-green-200 p-3 text-center text-xs uppercase text-green-800 ${className}`}>
      {text}
    </span>
  );
}
