import type { Dispatch, SetStateAction } from "react";
import React from "react";
import type { GroupBase, OptionProps } from "react-select";
import { components } from "react-select";
import type { Props } from "react-select";

import { classNames } from "@calcom/lib";
import { useLocale } from "@calcom/lib/hooks/useLocale";

import { Select } from "../select";

export type Option = {
  value: string;
  label: string;
};

const InputOption: React.FC<OptionProps<any, boolean, GroupBase<any>>> = ({
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  className,
  ...rest
}) => {
  const props = {
    ...innerProps,
  };

  return (
    <components.Option
      className={classNames(
        className,
        "!flex !cursor-pointer !py-3 text-[inherit]",
        isFocused && "dark:!bg-darkgray-200 !bg-gray-100",
        isSelected && "!bg-salmon-500"
      )}
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      innerProps={props}>
      <input
        type="checkbox"
        className="text-salmon-500 focus:ring-primary-500 h-4 w-4 rounded border-gray-300 ltr:mr-2 rtl:ml-2"
        checked={isSelected}
        readOnly
      />
      {children}
    </components.Option>
  );
};

type MultiSelectionCheckboxesProps = {
  options: { value: string | number; label: string }[];
  setSelected: Dispatch<SetStateAction<Option[]>>;
  selected: Option[];
  placeholder: string;
};

const MultiValue = ({ index, getValue }: { index: number; getValue: any }) => {
  const { t } = useLocale();

  const value = getValue();

  return (
    <>
      {!index && (
        <div className="flex">
          <div className={value.length > 1 ? "max-w-52" : "max-w-64"}>
            <span className="line-clamp-1">{t(value[0].label)}</span>
          </div>
          {value.length > 1 ? `, ${value.length - 1} More` : null}
        </div>
      )}
    </>
  );
};

export default function MultiSelectCheckboxes({
  options,
  isLoading,
  selected,
  setSelected,
  className,
  placeholder,
  instanceId,
}: Omit<Props, "options"> & MultiSelectionCheckboxesProps) {
  const additionalComponents = { MultiValue };

  return (
    <Select
      instanceId={instanceId}
      value={selected}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(s: any) => {
        setSelected(s);
      }}
      options={options}
      isMulti
      className={classNames(className ? className : "w-64 text-sm")}
      isSearchable={false}
      closeMenuOnSelect={false}
      placeholder={placeholder}
      hideSelectedOptions={false}
      isLoading={isLoading}
      components={{
        ...additionalComponents,
        Option: InputOption,
      }}
    />
  );
}
