import { officeContext } from "context/officeProvider";
import { UploadReportDialog } from "pages/practice";
import React, { useContext, useEffect, useState } from "react";

import { trpc } from "@calcom/trpc/react";
import type { MultiSelectCheckboxesOptionType } from "@calcom/ui";
import { Button, Checkbox, Dialog, DialogContent, MultiSelectCheckbox, Select } from "@calcom/ui";

type UploadReportDialogProps = {
  open: boolean;
  handler: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ReportDialog = ({ open, handler }: UploadReportDialogProps) => {
  const [reportUploadDlg, setReportUploadDlg] = useState(false);
  const [addAllOffices, setAddAllOffices] = useState(false);
  const [selectedOffices, setSelectedOffices] = useState<MultiSelectCheckboxesOptionType[]>([]);

  const { currentOffice } = useContext(officeContext);

  const { data: offices } = trpc.viewer.office.get.useQuery({});

  const OfficeOptions = offices
    ?.map((office) => ({ value: office.id, label: office.name }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  useEffect(() => {
    if (addAllOffices) {
      setSelectedOffices(OfficeOptions || []);
    } else {
      setSelectedOffices([]);
    }
  }, [addAllOffices]);

  return (
    <div>
      <Dialog open={open} onOpenChange={handler}>
        <DialogContent className="w-full !max-w-[750px] bg-gray-50 p-5 text-gray-600">
          <div>
            <div className="mb-4 flex items-center justify-between rounded-md border border-gray-200 bg-white px-5 py-4">
              <p className="text-base font-bold text-gray-800">Select the report to run</p>
              <div className="w-full md:w-80">
                <Select instanceId="upload" placeholder="Select Report Type" />
              </div>
            </div>
            <div className="flex flex-col items-end gap-2 rounded-md border border-gray-200 bg-white px-5 py-4">
              <div className="flex w-full items-center justify-between">
                <p className="text-base font-bold text-gray-800">Office(s)</p>
                <div className="w-full md:w-80">
                  <MultiSelectCheckbox
                    className="col-span-2 w-full"
                    isMulti
                    options={OfficeOptions}
                    placeholder="Select offices"
                    setSelected={setSelectedOffices}
                    selected={selectedOffices}
                    setValue={setSelectedOffices}
                  />
                </div>
              </div>
              <Checkbox
                description="Add All Offices"
                checked={addAllOffices}
                onChange={(e) => {
                  setAddAllOffices(e.target.checked);
                }}
              />
            </div>
            <div className="mt-10 flex justify-end gap-4">
              <Button
                onClick={() => {
                  setReportUploadDlg(true);
                }}>
                Upload Report
              </Button>
              <Button>Generate Report(s)</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <UploadReportDialog
        open={reportUploadDlg}
        handler={setReportUploadDlg}
        practice={offices?.find((office) => office.id === currentOffice)?.practice || undefined}
      />
    </div>
  );
};
