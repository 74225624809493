import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  OfficeStatus,
  UserPermissionRole,
  TransactionStatus,
  PaymentSource,
  PaymentType,
} from "@prisma/client";
import classNames from "classnames";
import type { GetServerSidePropsContext } from "next";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import dayjs from "@calcom/dayjs";
import Shell from "@calcom/features/shell/Shell";
import { useLocale } from "@calcom/lib/hooks/useLocale";
import { getOfficeIdsByUserRole } from "@calcom/lib/server/getOfficeIdsByUserRole";
import prisma from "@calcom/prisma";
import type { RouterOutputs } from "@calcom/trpc/react";
import { trpc } from "@calcom/trpc/react";
import { Button, Dialog, DialogContent, Select, Switch, TextField, Tooltip, showToast } from "@calcom/ui";
import { FiSearch } from "@calcom/ui/components/icon";

import { getSession } from "@lib/auth";
import { DateRangeOptions, TransactionsOptions, TransactionStatusOptions } from "@lib/constant";
import { formatNumber, formatPhoneNumber } from "@lib/practice/helper";
import type { inferSSRProps } from "@lib/types/inferSSRProps";

import BarchartSkeletonLoader from "@components/BarchartSkeletonLoader";
import DatePicker from "@components/DatePicker";
import DonutchartSkeletonLoader from "@components/DonutchartSkeletonLoader";
import Empty from "@components/Empty";
import { GreenCheckIcon, RedCheckIcon } from "@components/Icons";
import { LiteralAvatar } from "@components/LiteralAvatar";
import WidgetSkeletonLoader from "@components/WidgetSkeletonLoader";
import SkeletonLoader from "@components/booking/SkeletonLoader";
import LineChart from "@components/charts/line-chart";
import PieTransactionChart from "@components/charts/pie-transactions";
import BlueChip from "@components/chip/BlueChip";
import GreenChip from "@components/chip/GreenChip";
import RedChip from "@components/chip/RedChip";
import YellowChip from "@components/chip/YellowChip";
import { getLayout } from "@components/layouts/MainLayout";
import { useOfficeContext } from "@components/navbar";
import Pagination from "@components/ui/Pagination";

import { ssrInit } from "@server/lib/ssr";

import { NoneIcon } from "../../components/Icons";

type ChargesProps = inferSSRProps<typeof getServerSideProps>;

type Charge = RouterOutputs["viewer"]["insights"]["charges"]["payments"][number];

type ChargeDailogProps = {
  open: boolean;
  handler: React.Dispatch<React.SetStateAction<boolean>>;
  charge: Charge;
  timezone: string;
  officeId?: number;
};

export const ChargeDialog = ({ open, handler, charge, timezone, officeId }: ChargeDailogProps) => {
  const router = useRouter();
  const utils = trpc.useContext();
  const [recorded, setRecorded] = useState<boolean>(charge.recorded || false);
  const [refunding, setRefunding] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [patientId, setPatientId] = useState(-1);
  const [savingPatientId, setSavingPatientId] = useState(false);
  const [search, setSearch] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(search);
    }, 800);
    return () => clearTimeout(timeoutId);
  }, [search]);

  useEffect(() => {
    setRecorded(charge.recorded || false);
  }, [charge]);

  const { data: patientNames } = trpc.viewer.patients.getPatientNameList.useQuery(
    { locationId: charge.locationId, patientName: debouncedSearchQuery },
    { enabled: !charge.patient }
  );

  const recordToLedger = trpc.viewer.payments.recordToLedger.useMutation({
    onSuccess: async () => {
      console.log("recorded");
      await utils.viewer.payments.list.invalidate();
      await utils.viewer.insights.charges.invalidate();
    },
  });

  const refundTransfer = trpc.viewer.payments.refundPayment.useMutation({
    onSuccess: async (data) => {
      await utils.viewer.payments.list.invalidate();
      await utils.viewer.insights.charges.invalidate();
      handler(false);
      showToast("Success", "success");
      setRefunding(false);
    },
    onError: (err) => {
      showToast(err.message, "error");
      setRefunding(false);
    },
  });

  const savePatientId = trpc.viewer.payments.updatePatientId.useMutation({
    onSuccess: async (data) => {
      await utils.viewer.payments.list.invalidate();
      await utils.viewer.insights.charges.invalidate();
      showToast("Success", "success");
      setSavingPatientId(false);
    },
    onError: (err) => {
      showToast(err.message, "error");
      setSavingPatientId(false);
    },
  });

  const removePromptedPayment = trpc.viewer.payments.removePromptedPayment.useMutation({
    onSuccess: async (data) => {
      await utils.viewer.payments.list.invalidate();
      await utils.viewer.insights.charges.invalidate();
      handler(false);
      showToast("Success", "success");
      setRemoving(false);
    },
    onError: (err) => {
      showToast(err.message, "error");
      setRemoving(false);
    },
  });

  const onRecord = (checked: boolean) => {
    setRecorded(!recorded);
    recordToLedger.mutate({
      paymentId: charge.id,
      value: checked,
    });
  };

  const handleRefund = async () => {
    if (charge.transactionId) {
      setRefunding(true);
      refundTransfer.mutate({
        paymentId: charge.id,
      });
    }
  };

  const handleRemove = () => {
    if (charge.type === PaymentType.PROMPTED) {
      setRemoving(true);
      removePromptedPayment.mutate({
        id: charge.id,
      });
    }
  };

  const handlePatientIdSave = () => {
    setSavingPatientId(true);

    savePatientId.mutate({
      paymentId: charge.id,
      patientId,
    });
  };

  return (
    <Dialog open={open} onOpenChange={handler}>
      <DialogContent className="flex flex-col gap-4 bg-gray-50 text-gray-600">
        <div className="mb-6 flex items-center justify-between rounded-md border bg-white p-3">
          <div className="flex flex-col gap-2">
            <p className="font-bold">Charge</p>
            <p className="text-xs">Type: Payment</p>
          </div>
          <div className="flex flex-col gap-2">
            {(charge.transactionStatus === TransactionStatus.SUCCESSFUL ||
              charge.transactionStatus === TransactionStatus.REFUND) &&
              charge.type !== PaymentType.PROMPTED && (
                <>
                  <span className="text-sm text-green-900">
                    {charge.transactionStatus === TransactionStatus.SUCCESSFUL ? "SUCCEEDED" : "REFUNDED"}
                  </span>
                  <Switch
                    label="Recorded"
                    checked={recorded}
                    onCheckedChange={(checked) => onRecord(checked)}
                  />
                </>
              )}
            {charge.transactionStatus === TransactionStatus.FAILED && (
              <span className="text-sm text-red-900">FAILED</span>
            )}
          </div>
        </div>

        <div className="rounded-md border bg-white p-3">
          <div className="mb-2 flex items-center justify-between border-b border-gray-200 pb-4">
            <p className="font-bold">Patient</p>
            <Button disabled={!charge.patient} onClick={() => router.push(`/patients/${charge.patient?.id}`)}>
              View Patient
            </Button>
          </div>
          <div className="flex flex-col gap-3">
            {charge.patient && (
              <p>
                <span className="font-bold">Name: </span>
                {charge.patient?.firstName + " " + charge.patient?.lastName}
              </p>
            )}
            {!charge.patient && (
              <div className="flex gap-2">
                <Select
                  className="w-24"
                  options={patientNames || []}
                  onInputChange={(e) => setSearch(e)}
                  value={patientNames?.find((p) => p.value === patientId)}
                  onChange={(v) => v && setPatientId(v.value)}
                />
                <Button disabled={patientId === -1} loading={savingPatientId} onClick={handlePatientIdSave}>
                  Save
                </Button>
              </div>
            )}
            <p>
              <span className="font-bold">Patient ID: </span>
              {charge.patient?.foreignId}
            </p>
          </div>
        </div>

        <div className="rounded-md border bg-white p-3">
          <div className="flex items-center justify-between">
            <p className="font-bold">
              {charge.type === PaymentType.PROCESSED &&
                (charge.transactionStatus === TransactionStatus.FAILED ? "Failed Payment" : "Paid")}
              {charge.type === PaymentType.PROMPTED && "Prompted Payment"} $
              {formatNumber(charge.amount, "balance")}
            </p>
            {charge.type === PaymentType.PROCESSED &&
              charge.transactionStatus === TransactionStatus.SUCCESSFUL &&
              charge.transactionId && (
                <Button loading={refunding} onClick={() => handleRefund()}>
                  REFUND
                </Button>
              )}
            {charge.type === PaymentType.PROMPTED && (
              <Button loading={removing} onClick={() => handleRemove()}>
                REMOVE
              </Button>
            )}
            {charge.transactionStatus === TransactionStatus.REFUND && <YellowChip text="REFUNDED" />}
          </div>
          {charge.transactionStatus !== TransactionStatus.FAILED && charge.type === PaymentType.PROCESSED && (
            <div className="mt-4 flex flex-col gap-3 border-t border-gray-200 p-3">
              <p>
                <span className="font-bold">Paid On: </span>
                {charge.date ? dayjs.tz(charge.date, timezone).format("MM/DD/YYYY") : "N/A"}
              </p>
              <p>
                <span className="font-bold">Deposited On: </span>
                {charge.depositDate ? dayjs.tz(charge.depositDate, timezone).format("MM/DD/YYYY") : "N/A"}
              </p>
              {charge.transactionStatus === TransactionStatus.REFUND && (
                <p>
                  <span className="font-bold">Refunded On: </span>
                  {charge.refundDate ? dayjs.tz(charge.refundDate, timezone).format("MM/DD/YYYY") : "N/A"}
                </p>
              )}
              {charge.transactionStatus === TransactionStatus.SUCCESSFUL &&
                charge.type === PaymentType.PROCESSED &&
                charge.source === PaymentSource.AUTOMATEDAR &&
                charge.office?.applySurchargeFee && (
                  <p>
                    <span className="font-bold">Patient paid merchant fee</span>
                  </p>
                )}
              <div className="my-2 border border-b border-gray-200" />
              <table className="">
                <thead>
                  <tr className="border">
                    <th className="px-3 py-2 text-left">Amount</th>
                    <th className="px-3 py-2 text-center">Fees</th>
                    <th className="px-3 py-2 text-right">Net Deposit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border">
                    <td className="w-1/3 px-3 py-2 text-left">${formatNumber(charge.amount, "balance")}</td>
                    <td className="w-1/3 px-3 py-2 text-center">${formatNumber(charge.fee, "balance")}</td>
                    <td className="w-1/3 px-3 py-2 text-right">
                      ${formatNumber(charge.amount - charge.fee, "balance")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default function Charges({ billingOffice, reportLink, offices, user }: ChargesProps) {
  const { t } = useLocale();

  const { currentOffice } = useOfficeContext();

  const [animationParentRef] = useAutoAnimate<HTMLDivElement>();

  const OfficeOptions = offices
    .map((office) => ({ value: office.id, label: office.name }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  const [isMobile, setIsMobile] = useState(false);

  const [hideWidgets, setHideWidgets] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [chargeDialogOpen, setChargeDialogOpen] = useState(false);
  const [chargeOption, setChargeOption] = useState(TransactionsOptions[0]);
  const [transactionStatusOption, setTransactionStatusOption] = useState(TransactionStatusOptions[0]);
  const [selectedCharge, setSelectedCharge] = useState<Charge>();
  const [selectedOfficeId, setSelectedOfficeId] = useState<number>(0);
  const [officeOption, setOfficeOption] = useState<any>();
  const [widgetCnt, setWidgetCnt] = useState(0);

  const [startDate, setStartDate] = useState(dayjs().startOf("month").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [currentOption, setCurrentOption] = useState(DateRangeOptions[1]); // Set initial selected option
  const [isCustomDateRange, setCustomDateRange] = useState(false);

  const dateRangeOptions = isCustomDateRange
    ? [...DateRangeOptions, { value: 5, label: "Custom Date Range" }]
    : DateRangeOptions;

  const handleDateRangeChange = (option: { label: string; value: number }) => {
    setCurrentOption(option);
    setCustomDateRange(false);

    switch (option.label) {
      case "Month to Date":
        setStartDate(dayjs().startOf("month").format("YYYY-MM-DD"));
        setEndDate(dayjs().format("YYYY-MM-DD"));
        break;
      case "Today":
        setStartDate(dayjs().format("YYYY-MM-DD"));
        setEndDate(dayjs().format("YYYY-MM-DD"));
        break;
      case "Past 7 Days":
        setStartDate(dayjs().subtract(6, "day").format("YYYY-MM-DD"));
        setEndDate(dayjs().format("YYYY-MM-DD"));
        break;
      case "Last Month":
        setStartDate(dayjs().subtract(1, "month").startOf("month").format("YYYY-MM-DD"));
        setEndDate(dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"));
        break;
      case "All Time":
        setStartDate(dayjs("2023/02/02").startOf("year").format("YYYY-MM-DD"));
        setEndDate(dayjs().format("YYYY-MM-DD"));
        break;
      default:
        break;
    }
  };

  const handleDatePickerChange = (start: string, end: string) => {
    setStartDate(start);
    setEndDate(end);
    setCurrentOption({ value: 5, label: "Custom Date Range" });
    setCustomDateRange(true);
  };

  const { data, isLoading } = trpc.viewer.payments.list.useQuery(
    {
      billingOfficeId: Number(currentOffice),
      patientName: debouncedSearchQuery,
      pageNum,
      pageSize: perPage,
      type:
        chargeOption.value === "recorded" || chargeOption.value === "not_recorded"
          ? undefined
          : chargeOption.value,
      startDate,
      endDate,
      recorded:
        chargeOption.value === "recorded" || chargeOption.value === "not_recorded"
          ? chargeOption.value === "recorded"
          : undefined,
    },
    {
      enabled: !!currentOffice,
    }
  );

  const { data: metrics, isLoading: widgetLoading } = trpc.viewer.payments.getMetrics.useQuery(
    {
      startDate: startDate,
      endDate: endDate,
      type: currentOption.label,
      paymentType:
        chargeOption.value === "recorded" || chargeOption.value === "not_recorded"
          ? undefined
          : chargeOption.value,
      officeId: Number(currentOffice),
    },
    {
      enabled: !!currentOffice,
    }
  );

  const changeCurrentOffice = trpc.viewer.auth.changeCurrentOffice.useMutation({});

  const handleNext = () => {
    const width = window.innerWidth;

    if (width < 750) {
      setIsMobile(true);
      if (widgetCnt >= 3) return;
    } else {
      setIsMobile(false);
      if (widgetCnt >= 1) return;
    }
    setWidgetCnt(widgetCnt + 1);
  };

  const handlePrev = () => {
    if (widgetCnt <= 0) return;
    setWidgetCnt(widgetCnt - 1);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
      setPageNum(0);
    }, 800);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  useEffect(() => {
    if (!currentOffice && OfficeOptions.length) {
      setOfficeOption(OfficeOptions[0]);
    } else if (currentOffice) {
      setOfficeOption(OfficeOptions.find((office) => office.value === currentOffice));
    }
  }, []);

  return (
    <Shell
      heading={t("transactions")}
      subtitle={
        <p className="mt-2 w-[600px] leading-6">
          View all transactions at your office, breaking down the amount, fees, and net deposit amounts
          deposited to your office&apos;s bank accounts.
        </p>
      }
      // CTA={
      //   <div className="hidden flex-col gap-4 md:flex md:flex-row">
      //     <div className="hidden w-full md:block md:w-60">
      //       <Select
      //         instanceId="office"
      //         options={OfficeOptions}
      //         value={officeOption}
      //         onChange={(option) => {
      //           if (option) {
      //             changeCurrentOffice.mutate({ officeId: option.value });
      //             setOfficeOption(option);
      //           }
      //         }}
      //       />
      //     </div>
      //   </div>
      // }
    >
      <div className="flex w-full flex-col" ref={animationParentRef}>
        <div className="mb-4 flex flex-col flex-wrap justify-end gap-4 md:flex-row xl:justify-start">
          <div className="flex w-full flex-col justify-between gap-4 md:flex-row">
            {/* <div className="block w-full md:hidden md:w-60">
              <Select
                instanceId="office"
                options={OfficeOptions}
                value={officeOption}
                onChange={(option) => {
                  if (option) {
                    changeCurrentOffice.mutate({ officeId: option.value });
                    setOfficeOption(option);
                  }
                }}
              />
            </div>
            <div className="flex flex-col gap-4 md:hidden md:flex-row">
              <Button
                className="block py-1 text-center"
                color="tertiary"
                onClick={() => {
                  reportGenerate(`${reportLink}&officeId=${officeOption.value}`, officeOption.label);
                }}>
                Generate a Report
              </Button>
            </div> */}
            <div className="relative w-full md:w-72">
              <TextField
                className="mb-0 border-gray-200 pl-8"
                placeholder="Patient Name or ID"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <FiSearch className="absolute top-2.5 left-2 h-4 w-4 cursor-pointer text-gray-500" />
            </div>

            <div className="w-full md:w-56">
              <Select
                instanceId="charge"
                options={TransactionsOptions}
                value={chargeOption}
                onChange={(option) => {
                  if (option) {
                    setChargeOption(option);
                    setPageNum(0);
                  }
                }}
              />
            </div>

            <div className="w-full md:w-44">
              <Select
                instanceId="charge"
                options={TransactionStatusOptions}
                value={transactionStatusOption}
                onChange={(option) => option && setTransactionStatusOption(option)}
              />
            </div>
            <div className="relative w-full md:w-56">
              <Select
                instanceId="date"
                options={dateRangeOptions}
                value={currentOption}
                onChange={(option) => option && handleDateRangeChange(option)}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderColor: "rgb(229 231 235)",
                    paddingLeft: "28px",
                  }),
                }}
              />
              <div className="pointer-events-none absolute inset-0 right-auto flex items-center">
                <svg
                  className="ml-3 h-4 w-4 fill-current text-slate-500 dark:text-slate-400"
                  viewBox="0 0 16 16">
                  <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
                </svg>
              </div>
            </div>

            <div className="flex justify-end gap-4">
              <div className="w-full md:w-72">
                <DatePicker
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={handleDatePickerChange}
                />
              </div>
              <div className="flex gap-2 md:hidden">
                <Button
                  className="rounded-full border border-gray-200 bg-white"
                  color="minimal"
                  onClick={handlePrev}
                  disabled={widgetCnt === 0}>
                  {"<"}
                </Button>
                <Button
                  className="rounded-full border border-gray-200 bg-white"
                  color="minimal"
                  onClick={handleNext}
                  disabled={isMobile ? widgetCnt === 2 : widgetCnt === 1}>
                  {">"}
                </Button>
              </div>
            </div>
          </div>
        </div>

        {!hideWidgets && (
          <div className="mb-4 flex w-full gap-4 overflow-hidden">
            <div
              className={classNames(
                "flex w-full grid-cols-3 gap-4 transition-all md:grid",
                widgetCnt === 0
                  ? "translate-x-0"
                  : widgetCnt === 1
                  ? "-translate-x-[103%] md:-translate-x-[33.3%]"
                  : widgetCnt === 2
                  ? "-translate-x-[206%] md:-translate-x-[66.6%]"
                  : widgetCnt === 3
                  ? "-translate-x-[309%] md:-translate-x-[100%]"
                  : "-translate-x-[412%] md:-translate-x-[133%]"
              )}>
              {/* Gross Volume */}
              <div className="relative w-[100%] min-w-[100%] overflow-hidden rounded-md border border-gray-200 bg-white p-5 md:min-w-[32%]">
                {widgetLoading ? (
                  <WidgetSkeletonLoader />
                ) : (
                  <>
                    <div className="flex w-full items-center text-left text-sm text-gray-500">
                      <div>
                        <Tooltip
                          content={
                            "$" + formatNumber(metrics?.grossVolume, "balance").toString().split(".")[0]
                          }
                          className="bg-white text-gray-600">
                          <span className="line-clamp-1 mr-3 max-w-[168px] break-words text-[28px] font-bold leading-7 text-black">
                            ${formatNumber(metrics?.grossVolume, "balance").toString().split(".")[0]}
                          </span>
                        </Tooltip>
                      </div>
                      <div>
                        <Tooltip className="bg-white text-gray-600" content="Gross Payment Volume">
                          <p className="line-clamp-1 text-base font-bold text-black">Gross Payment Volume</p>
                        </Tooltip>
                        <p className="flex items-center gap-4">
                          {currentOption.label === "Custom Date Range" ? "Custom" : currentOption.label}
                          {currentOption.value !== 0 && !!data?.payments?.length && (
                            <>
                              {metrics?.trendGross >= 0 ? (
                                <span
                                  className={classNames(
                                    "flex items-center gap-2 rounded-sm bg-green-200 px-1 text-xs text-[#00A171]",
                                    { hidden: currentOption === DateRangeOptions[0] }
                                  )}>
                                  {formatNumber(Math.floor(metrics?.trendGross), "number")} %
                                  {/* <IncreaseGreenIcon width={12} /> */}
                                </span>
                              ) : (
                                <span
                                  className={classNames(
                                    "flex items-center gap-2 rounded-sm bg-red-200 px-1 text-xs text-red-800",
                                    { hidden: currentOption === DateRangeOptions[0] }
                                  )}>
                                  {formatNumber(Math.floor(metrics?.trendGross), "number")} %
                                  {/* <DecreaseIcon width={12} /> */}
                                </span>
                              )}
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="my-6 border-b border-gray-200" />
                    <p className="text-sm text-gray-500">
                      Estimated revenue from all types of payments at this location.
                    </p>
                  </>
                )}
              </div>

              {/* Net Volume */}
              <div className="relative min-w-[100%] overflow-hidden rounded-md border border-gray-200 bg-white p-5 md:min-w-[32.5%]">
                {widgetLoading ? (
                  <WidgetSkeletonLoader />
                ) : (
                  <>
                    <div className="flex w-full items-center text-left text-sm text-gray-500">
                      <Tooltip
                        content={"$" + formatNumber(metrics?.netVolume, "balance").toString().split(".")[0]}
                        className="bg-white text-gray-600">
                        <span className="line-clamp-1 mr-3 max-w-[170px] break-words text-[28px] font-bold leading-7 text-black">
                          ${formatNumber(metrics?.netVolume, "balance").toString().split(".")[0]}
                        </span>
                      </Tooltip>
                      <div>
                        <Tooltip className="bg-white text-gray-600" content="Net Payment Volume">
                          <p className="line-clamp-1 text-base font-bold text-black">Net Payment Volume</p>
                        </Tooltip>
                        <p className="flex items-center gap-4">
                          {currentOption.label === "Custom Date Range" ? "Custom" : currentOption.label}
                          {currentOption.value !== 0 && !!data?.payments?.length && (
                            <>
                              {metrics?.trendNet >= 0 ? (
                                <span
                                  className={classNames(
                                    "flex items-center gap-2 rounded-sm bg-green-200 px-1 text-xs text-[#00A171]",
                                    { hidden: currentOption === DateRangeOptions[0] }
                                  )}>
                                  {formatNumber(Math.floor(metrics?.trendNet), "number")} %
                                  {/* <IncreaseGreenIcon width={12} /> */}
                                </span>
                              ) : (
                                <span
                                  className={classNames(
                                    "flex items-center gap-2 rounded-sm bg-red-200 px-1 text-xs text-red-800",
                                    { hidden: currentOption === DateRangeOptions[0] }
                                  )}>
                                  {formatNumber(Math.floor(metrics?.trendNet), "number")} %
                                  {/* <DecreaseIcon width={12} /> */}
                                </span>
                              )}
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="my-6 border-b border-gray-200" />
                    <p className="text-sm text-gray-500">
                      Estimated revenue from all types of payments after fees, refunds, and disputes at this
                      location.
                    </p>
                  </>
                )}
              </div>

              {/* Spend / Patient */}
              <div className="relative w-[100%] min-w-[100%] overflow-hidden rounded-md border border-gray-200 bg-white p-5 md:min-w-[32%]">
                {widgetLoading ? (
                  <WidgetSkeletonLoader />
                ) : (
                  <>
                    <div className="flex w-full items-center text-left text-sm text-gray-500">
                      <Tooltip
                        content={
                          "$" + formatNumber(metrics?.spendPatient, "balance").toString().split(".")[0]
                        }
                        className="bg-white text-gray-600">
                        <span className="line-clamp-1 mr-3 max-w-[168px] break-words text-[28px] font-bold leading-7 text-black">
                          ${formatNumber(metrics?.spendPatient, "balance").toString().split(".")[0]}
                        </span>
                      </Tooltip>
                      <div>
                        <Tooltip className="bg-white text-gray-600" content="Spend / Patient">
                          <p className="line-clamp-1 text-base font-bold text-black">Spend / Patient</p>
                        </Tooltip>
                        <p className="flex items-center gap-4">
                          {currentOption.label === "Custom Date Range" ? "Custom" : currentOption.label}
                          {currentOption.value !== 0 && !!data?.payments?.length && (
                            <>
                              {metrics?.trendSpend >= 0 ? (
                                <span
                                  className={classNames(
                                    "flex items-center gap-2 rounded-sm bg-green-200 px-1 text-xs text-[#00A171]",
                                    { hidden: currentOption === DateRangeOptions[0] }
                                  )}>
                                  {formatNumber(Math.floor(metrics?.trendSpend), "number")} %
                                  {/* <IncreaseGreenIcon width={12} /> */}
                                </span>
                              ) : (
                                <span
                                  className={classNames(
                                    "flex items-center gap-2 rounded-sm bg-red-200 px-1 text-xs text-red-800",
                                    { hidden: currentOption === DateRangeOptions[0] }
                                  )}>
                                  {formatNumber(Math.floor(metrics?.trendSpend), "number")} %
                                  {/* <DecreaseIcon width={12} /> */}
                                </span>
                              )}
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="my-6 border-b border-gray-200" />
                    <p className="text-sm text-gray-500">
                      Estimated revenue calculated per patient at this location.
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        {!hideWidgets && (
          <>
            {widgetLoading && (
              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row">
                <div className="md:flex-grow">
                  <BarchartSkeletonLoader />
                </div>
                <div className="md:min-w-[365px]">
                  <DonutchartSkeletonLoader />
                </div>
              </div>
            )}
            {!widgetLoading && (
              <div className="mb-4 grid w-full grid-cols-1 flex-col gap-y-4 md:grid-cols-3 md:flex-row md:gap-4">
                <div className="col-span-2 md:flex-grow">
                  <LineChart timeRange={currentOption.label} metrics={metrics} />
                </div>
                <div className="md:min-w-[365px]">
                  <PieTransactionChart data={metrics?.transaction || []} />
                </div>
              </div>
            )}
          </>
        )}

        {!isLoading && !!data?.payments?.length && (
          <div>
            <div className="min-h-[650px] overflow-x-auto rounded-md border border-gray-200 bg-white">
              <table className="w-full max-w-full table-auto">
                <thead>
                  <tr>
                    <th className="border-b bg-gray-50 p-3 text-left text-sm text-gray-500">Patient</th>
                    <th className="border-b bg-gray-50 p-3 text-left text-sm text-gray-500">Paid Date</th>
                    <th className="border-b bg-gray-50 p-3 text-left text-sm text-gray-500">Amount</th>
                    <th className="border-b bg-gray-50 p-3 text-left text-sm text-gray-500">Fees</th>
                    <th className="border-b bg-gray-50 p-3 text-left text-sm text-gray-500">Net Deposit</th>
                    <th className="border-b bg-gray-50 p-3 text-left text-sm text-gray-500">Recorded</th>
                    <th className="border-b bg-gray-50 p-3 text-left text-sm text-gray-500">Source</th>
                    <th className="border-b bg-gray-50 p-3 text-left text-sm text-gray-500">Status</th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white" data-testid="appointments">
                  {data?.payments.length &&
                    data?.payments.map((item, key) => (
                      <tr
                        className="cursor-pointer text-sm even:bg-gray-50 hover:bg-gray-100"
                        key={key}
                        onClick={() => {
                          setSelectedCharge(item);
                          setChargeDialogOpen(true);
                          setSelectedOfficeId(item.patient?.billingOffice?.id || 0);
                        }}>
                        <td
                          className="w-[200px] min-w-[200px] p-3 text-gray-600"
                          onClick={(e) => e.stopPropagation()}>
                          <Link
                            href={`/patients/${item.patient?.id}`}
                            className={!item.patient ? "pointer-events-none" : ""}>
                            <Tooltip
                              content={
                                <div className="space-y-1">
                                  <p>
                                    Name:{" "}
                                    {item.patient
                                      ? item.patient?.firstName + " " + item.patient?.lastName
                                      : "Unable to Match"}
                                  </p>
                                  <p>PID: {item.patient?.foreignId}</p>
                                  {item.patient?.email && <p>Email: {item.patient?.email}</p>}
                                  {item.patient?.phoneNumber && (
                                    <p>Phone: {formatPhoneNumber(item.patient?.phoneNumber)}</p>
                                  )}
                                  <p>DOB: {dayjs(item.patient?.birthday).format("DD/MM/YYYY")}</p>
                                  <p>Age: {dayjs().diff(item.patient?.birthday, "year")}</p>
                                </div>
                              }
                              className="bg-white text-gray-600">
                              <div className="flex items-center gap-2">
                                <LiteralAvatar
                                  fullName={item.patient?.firstName + " " + item.patient?.lastName}
                                />
                                <div>
                                  <p className="line-clamp-1 w-[150px]">
                                    {item.patient
                                      ? item.patient?.firstName + " " + item.patient?.lastName
                                      : "Unable to Match"}
                                  </p>
                                  <p className="text-xs">PID: {item.patient?.foreignId}</p>
                                </div>
                              </div>
                            </Tooltip>
                          </Link>
                        </td>
                        <td className="w-[150px] p-3 text-gray-600">
                          <Tooltip
                            className="bg-white text-gray-600"
                            content={
                              <p>
                                Deposit Date:{" "}
                                {item.type === "PROMPTED"
                                  ? "N/A"
                                  : dayjs(item.depositDate)
                                      .tz(billingOffice.timeZone as string)
                                      .format("MM/DD/YYYY")}
                              </p>
                            }>
                            <p>
                              {dayjs(item.date)
                                .tz(billingOffice.timeZone as string)
                                .format("MM/DD/YYYY")}
                            </p>
                          </Tooltip>
                        </td>
                        <td className="w-[150px] p-3 text-gray-600">
                          ${formatNumber(item.amount, "balance")}
                        </td>
                        <td className="w-[100px] p-3 text-gray-600">
                          {item.type === "PROMPTED" ? "N/A" : "$" + formatNumber(item.fee, "balance")}
                        </td>
                        <td className="w-[150px] p-3 text-gray-600">
                          ${formatNumber(item.amount - item.fee, "balance")}
                        </td>
                        <td className="w-[150px] p-3 text-gray-600">
                          {item.type === PaymentType.PROCESSED &&
                            item.transactionStatus !== TransactionStatus.FAILED &&
                            (item.recorded ? <GreenCheckIcon /> : <RedCheckIcon />)}
                          {(item.type === PaymentType.PROMPTED ||
                            item.transactionStatus === TransactionStatus.FAILED) && <NoneIcon />}
                        </td>
                        <td className="w-[170px] p-3 text-sm text-gray-600">
                          {item.source === PaymentSource.AUTOMATEDAR ? (
                            <Tooltip
                              content={item.type === PaymentType.PROMPTED ? "Prompted" : "Processed"}
                              className="bg-white text-gray-500">
                              <p>Automated A/R</p>
                            </Tooltip>
                          ) : item.source === PaymentSource.MEMBERSHIP ? (
                            "Membership"
                          ) : item.source === PaymentSource.ONLINE ? (
                            "Online Payment"
                          ) : item.source === PaymentSource.PAYMENTPLAN ? (
                            "Payment Plan"
                          ) : item.source === PaymentSource.TERMINAL ? (
                            "Terminal Payment"
                          ) : item.source === PaymentSource.VIRTUAL ? (
                            "Virtual Payment"
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td className="w-[150px] p-3 text-sm text-gray-600">
                          {item.transactionStatus === TransactionStatus.SUCCESSFUL &&
                            item.type === PaymentType.PROCESSED && (
                              <GreenChip text={item.transactionStatus} />
                            )}
                          {item.transactionStatus === TransactionStatus.FAILED && (
                            <RedChip text={item.transactionStatus} />
                          )}
                          {item.transactionStatus === TransactionStatus.REFUND && (
                            <YellowChip text="REFUNDED" />
                          )}
                          {item.type === PaymentType.PROMPTED && <BlueChip text="PROMPTED" />}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <Pagination
              page={pageNum}
              count={data.count as number}
              perPage={perPage}
              handleNext={(page) => {
                setPageNum(page);
              }}
              handlePrev={(page) => {
                setPageNum(page);
              }}
              handleRowsPerPage={(rows) => {
                setPageNum(0);
                setPerPage(rows);
              }}
            />
          </div>
        )}
        {isLoading && <SkeletonLoader />}
        {!isLoading && Number(data?.payments?.length) === 0 && (
          <div className="flex justify-center rounded-md border border-gray-200 bg-white py-10">
            <div className="w-[450px]">
              <Empty
                title="No Data"
                description="There are currently no transactions for this office. If you have any questions, please contact your Caresuite team member for assistance."
              />
            </div>
          </div>
        )}
      </div>

      {selectedCharge && (
        <ChargeDialog
          open={chargeDialogOpen}
          handler={setChargeDialogOpen}
          charge={selectedCharge as Charge}
          timezone={billingOffice.timeZone}
          officeId={selectedOfficeId}
        />
      )}
    </Shell>
  );
}

Charges.getLayout = getLayout;

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const { req } = context;
  const session = await getSession({ req });
  const ssr = await ssrInit(context);

  if (!session?.user?.id) {
    return {
      redirect: {
        permanent: false,
        destination: "/auth/login",
      },
    };
  }

  const user = await prisma.user.findUnique({
    where: {
      id: session.user.id,
    },
    select: {
      currentOffice: true,
      role: true,
      id: true,
      groupId: true,
    },
  });

  if (!user) return { notFound: true };

  const billingOffice = await prisma.billingOffice.findFirst({
    where: {
      id: Number(user?.currentOffice),
    },
    select: {
      timeZone: true,
    },
  });

  const officeIds = await getOfficeIdsByUserRole(user.id, user.role);

  const offices = await prisma.billingOffice.findMany({
    where: {
      id: {
        in: officeIds,
      },
      status: {
        in: [OfficeStatus.ACTIVE, OfficeStatus.PAUSE],
      },
      groupId: user.role == UserPermissionRole.ADMIN ? undefined : user.groupId,
    },
    select: {
      officename: true,
      id: true,
      name: true,
      status: true,
    },
  });

  if (!billingOffice) return { notFound: true };

  const reportLink = `${process.env.REPORT_ENGINE}/report?api-key=${process.env.REPORT_ENGINE_API_KEY}`;

  return {
    props: {
      billingOffice,
      reportLink,
      currentOffice: user?.currentOffice,
      offices,
      user,
      trpcState: ssr.dehydrate(),
    },
  };
};
