import { PracticeManagement } from ".prisma/client";

export const formatPMS = (pms: PracticeManagement) => {
  switch (pms) {
    case PracticeManagement.CURVE:
      return "Curve";
    case PracticeManagement.OPENDENTAL:
      return "OpenDental";
    case PracticeManagement.OPENDENTAL_NI:
      return "OpenDental NI";
    case PracticeManagement.WINOMS:
      return "WinOMS";
    case PracticeManagement.WINOMS_SPECIAL:
      return "WinOMS Special";
    case PracticeManagement.DENTRIX:
      return "Dentrix";
    case PracticeManagement.DENTRIX_NI:
      return "Dentrix NI";
    case PracticeManagement.DENTRIX_ASCEND:
      return "Dentrix Ascend";
    case PracticeManagement.EAGLESOFT:
      return "Eaglesoft";
    case PracticeManagement.EAGLESOFT_NI:
      return "Eaglesoft NI";
    case PracticeManagement.FUSE:
      return "Fuse";
    case PracticeManagement.DSN:
      return "DSN";
    case PracticeManagement.DENTICON:
      return "Denticon";
    case PracticeManagement.SOFTDENT:
      return "Softdent";
    case PracticeManagement.SENSI_CLOUD:
      return "Sensi Cloud";
    default:
      return "Other";
  }
};
