import React from "react";

import { TopNavBar } from "../navbar";

function MainLayout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <TopNavBar />
      {children}
    </>
  );
}

export const getLayout = (page: React.ReactElement) => <MainLayout>{page}</MainLayout>;
