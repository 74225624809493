import React, { useRef, useState, useEffect } from "react";
import Flatpickr from "react-flatpickr";

import dayjs from "@calcom/dayjs";
import { classNames } from "@calcom/lib";

interface DatePickerProps {
  startDate: string;
  endDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  onChange?: (startDate: string, endDate: string) => void; // Define onChange prop
}

export default function DatePicker({
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  onChange,
}: DatePickerProps) {
  const pickerRef = useRef(null);
  const [dates, setDates] = useState<Date[]>([dayjs(startDate).toDate(), dayjs(endDate).toDate()]);

  useEffect(() => {
    setDates([dayjs(startDate).toDate(), dayjs(endDate).toDate()]);
  }, [startDate, endDate]);

  const updateDate = (dates: Date[]) => {
    if (dates.length > 1) {
      const start = dayjs(dates[0]).format("YYYY-MM-DD");
      const end = dayjs(dates[1]).format("YYYY-MM-DD");

      setStartDate(start);
      setEndDate(end);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      pickerRef.current?.flatpickr?.close();
      setDates(dates);
      if (onChange) {
        onChange(start, end);
      }
    }
  };

  return (
    <div className="relative">
      <div className="w-full">
        <Flatpickr
          ref={pickerRef}
          className={classNames(
            "form-input w-full rounded-md border-gray-200 pl-9 text-sm leading-[18px] text-gray-700 shadow-none",
            "hover:text-slate-600 dark:bg-slate-800 dark:text-slate-300 dark:hover:text-slate-200"
          )}
          options={{
            mode: "range",
            static: true,
            monthSelectorType: "static",
            dateFormat: "M j, Y",
            prevArrow:
              '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
            nextArrow:
              '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
          }}
          value={dates}
          onChange={updateDate}
          onClose={() => {
            return;
          }}
        />
      </div>
      <div className="pointer-events-none absolute inset-0 right-auto flex items-center">
        <svg className="ml-3 h-4 w-4 fill-current text-slate-500 dark:text-slate-400" viewBox="0 0 16 16">
          <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
        </svg>
      </div>
    </div>
  );
}
