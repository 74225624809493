/**
    This file is autogenerated using the command `yarn app-store:build --watch`.
    Don't modify this file manually.
**/
import amie_config_json from "./amie/config.json";
import { metadata as applecalendar__metadata_ts } from "./applecalendar/_metadata";
import around_config_json from "./around/config.json";
import { metadata as caldavcalendar__metadata_ts } from "./caldavcalendar/_metadata";
import campfire_config_json from "./campfire/config.json";
import closecom_config_json from "./closecom/config.json";
import { metadata as dailyvideo__metadata_ts } from "./dailyvideo/_metadata";
import routing_forms_config_json from "./ee/routing-forms/config.json";
import { metadata as exchange2013calendar__metadata_ts } from "./exchange2013calendar/_metadata";
import { metadata as exchange2016calendar__metadata_ts } from "./exchange2016calendar/_metadata";
import exchangecalendar_config_json from "./exchangecalendar/config.json";
import facetime_config_json from "./facetime/config.json";
import fathom_config_json from "./fathom/config.json";
import ga4_config_json from "./ga4/config.json";
import { metadata as giphy__metadata_ts } from "./giphy/_metadata";
import { metadata as googlecalendar__metadata_ts } from "./googlecalendar/_metadata";
import { metadata as googlevideo__metadata_ts } from "./googlevideo/_metadata";
import { metadata as hubspot__metadata_ts } from "./hubspot/_metadata";
import { metadata as huddle01video__metadata_ts } from "./huddle01video/_metadata";
import { metadata as jitsivideo__metadata_ts } from "./jitsivideo/_metadata";
import { metadata as larkcalendar__metadata_ts } from "./larkcalendar/_metadata";
import n8n_config_json from "./n8n/config.json";
import { metadata as office365calendar__metadata_ts } from "./office365calendar/_metadata";
import office365video_config_json from "./office365video/config.json";
import ping_config_json from "./ping/config.json";
import pipedream_config_json from "./pipedream/config.json";
import plausible_config_json from "./plausible/config.json";
import qr_code_config_json from "./qr_code/config.json";
import rainbow_config_json from "./rainbow/config.json";
import raycast_config_json from "./raycast/config.json";
import riverside_config_json from "./riverside/config.json";
import salesforce_config_json from "./salesforce/config.json";
import sendgrid_config_json from "./sendgrid/config.json";
import signal_config_json from "./signal/config.json";
import sirius_video_config_json from "./sirius_video/config.json";
import { metadata as stripepayment__metadata_ts } from "./stripepayment/_metadata";
import { metadata as tandemvideo__metadata_ts } from "./tandemvideo/_metadata";
import telegram_config_json from "./telegram/config.json";
import basic_config_json from "./templates/basic/config.json";
import booking_pages_tag_config_json from "./templates/booking-pages-tag/config.json";
import event_type_app_card_config_json from "./templates/event-type-app-card/config.json";
import event_type_location_video_static_config_json from "./templates/event-type-location-video-static/config.json";
import general_app_settings_config_json from "./templates/general-app-settings/config.json";
import link_as_an_app_config_json from "./templates/link-as-an-app/config.json";
import typeform_config_json from "./typeform/config.json";
import vimcal_config_json from "./vimcal/config.json";
import { metadata as vital__metadata_ts } from "./vital/_metadata";
import weather_in_your_calendar_config_json from "./weather_in_your_calendar/config.json";
import whatsapp_config_json from "./whatsapp/config.json";
import whereby_config_json from "./whereby/config.json";
import { metadata as wipemycalother__metadata_ts } from "./wipemycalother/_metadata";
import wordpress_config_json from "./wordpress/config.json";
import { metadata as zapier__metadata_ts } from "./zapier/_metadata";
import { metadata as zoomvideo__metadata_ts } from "./zoomvideo/_metadata";

export const appStoreMetadata = {
  amie: amie_config_json,
  applecalendar: applecalendar__metadata_ts,
  around: around_config_json,
  caldavcalendar: caldavcalendar__metadata_ts,
  campfire: campfire_config_json,
  closecom: closecom_config_json,
  dailyvideo: dailyvideo__metadata_ts,
  "routing-forms": routing_forms_config_json,
  exchange2013calendar: exchange2013calendar__metadata_ts,
  exchange2016calendar: exchange2016calendar__metadata_ts,
  exchangecalendar: exchangecalendar_config_json,
  facetime: facetime_config_json,
  fathom: fathom_config_json,
  ga4: ga4_config_json,
  giphy: giphy__metadata_ts,
  googlecalendar: googlecalendar__metadata_ts,
  googlevideo: googlevideo__metadata_ts,
  hubspot: hubspot__metadata_ts,
  huddle01video: huddle01video__metadata_ts,
  jitsivideo: jitsivideo__metadata_ts,
  larkcalendar: larkcalendar__metadata_ts,
  n8n: n8n_config_json,
  office365calendar: office365calendar__metadata_ts,
  office365video: office365video_config_json,
  ping: ping_config_json,
  pipedream: pipedream_config_json,
  plausible: plausible_config_json,
  qr_code: qr_code_config_json,
  rainbow: rainbow_config_json,
  raycast: raycast_config_json,
  riverside: riverside_config_json,
  salesforce: salesforce_config_json,
  sendgrid: sendgrid_config_json,
  signal: signal_config_json,
  sirius_video: sirius_video_config_json,
  stripepayment: stripepayment__metadata_ts,
  tandemvideo: tandemvideo__metadata_ts,
  telegram: telegram_config_json,
  basic: basic_config_json,
  "booking-pages-tag": booking_pages_tag_config_json,
  "event-type-app-card": event_type_app_card_config_json,
  "event-type-location-video-static": event_type_location_video_static_config_json,
  "general-app-settings": general_app_settings_config_json,
  "link-as-an-app": link_as_an_app_config_json,
  typeform: typeform_config_json,
  vimcal: vimcal_config_json,
  vital: vital__metadata_ts,
  weather_in_your_calendar: weather_in_your_calendar_config_json,
  whatsapp: whatsapp_config_json,
  whereby: whereby_config_json,
  wipemycalother: wipemycalother__metadata_ts,
  wordpress: wordpress_config_json,
  zapier: zapier__metadata_ts,
  zoomvideo: zoomvideo__metadata_ts,
};
