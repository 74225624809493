import {
  MessageLogType,
  PaymentType,
  PracticeManagement,
  OfficeStatus,
  MembershipType,
  PaymentCycle,
  TransactionStatus,
  MessageSource,
} from "@prisma/client";

import { formatPMS } from "@calcom/lib/format";

type OutreachOptionsType = {
  value?: MessageLogType;
  label: string;
};

type StringOptionsType = {
  value: string;
  label: string;
};

type MembershipoptionType = {
  value: MembershipType;
  label: string;
};

export const OutreachOptions: Array<OutreachOptionsType> = [
  { value: undefined, label: "All Outreach" },
  { value: MessageLogType.SMS, label: "SMS" },
  { value: MessageLogType.EMAIL, label: "EMAIL" },
];

type ChargeOptionsType = {
  value?: PaymentType;
  label: string;
};

export const ChargeOptions: Array<ChargeOptionsType> = [
  { value: undefined, label: "All Types" },
  { value: PaymentType.PROMPTED, label: "Prompted" },
  { value: PaymentType.PROCESSED, label: "Processed" },
];

export const TransactionStatusOptions: Array<{ value?: TransactionStatus; label: string }> = [
  { value: undefined, label: "All Statues" },
  { value: TransactionStatus.SUCCESSFUL, label: "Successful" },
  { value: TransactionStatus.REFUND, label: "Refund" },
  { value: TransactionStatus.FAILED, label: "Failed" },
];

export const MessageSourceOptions: Array<{ value?: MessageSource; label: string }> = [
  { value: undefined, label: "All Sources" },
  { value: MessageSource.AUTOMATEDAR, label: "Successful" },
  { value: MessageSource.MEMBERSHIP, label: "Membership" },
  { value: MessageSource.PAYMENTPLAN, label: "Payment Plan" },
  { value: MessageSource.PAYMENT_REQUEST, label: "Payment Request" },
  { value: MessageSource.CARD_REQUEST, label: "Card Request" },
  { value: MessageSource.CONFIRMATION, label: "Confirmation" },
];

type TransactionOptionType = {
  value?: PaymentType | "recorded" | "not_recorded";
  label: string;
};

export const TransactionsOptions: Array<TransactionOptionType> = [
  { value: undefined, label: "All Transactions" },
  { value: PaymentType.PROMPTED, label: "Prompted" },
  { value: PaymentType.PROCESSED, label: "Processed" },
  { value: "recorded", label: "Recorded" },
  { value: "not_recorded", label: "Not Recorded" },
];

export const ProcessPaymentOptions: Array<MembershipoptionType> = [
  { value: MembershipType.YEARLY, label: "Yearly" },
  { value: MembershipType.MONTHLY, label: "Monthly" },
];

export const ProcessPaymentExtendedOptions: Array<{ value: PaymentCycle; label: string }> = [
  { value: PaymentCycle.WEEKLY, label: "Weekly" },
  { value: PaymentCycle.BIWEEKLY, label: "Biweekly" },
  { value: PaymentCycle.MONTHLY, label: "Monthly" },
  { value: PaymentCycle.YEARLY, label: "Yearly" },
];

export const PaymentPlanPeriodOptions: Array<{ value: PaymentCycle; label: string }> = [
  { value: PaymentCycle.WEEKLY, label: "Weekly" },
  { value: PaymentCycle.BIWEEKLY, label: "Biweekly" },
  { value: PaymentCycle.MONTHLY, label: "Monthly" },
  { value: PaymentCycle.YEARLY, label: "Yearly" },
];

export const PaymentCountOptions: Array<{ value: number; label: string }> = [
  { value: 2, label: "2 installments" },
  { value: 3, label: "3 installments" },
  { value: 4, label: "4 installments" },
  { value: 5, label: "5 installments" },
  { value: 6, label: "6 installments" },
  { value: 7, label: "7 installments" },
  { value: 8, label: "8 installments" },
  { value: 9, label: "9 installments" },
  { value: 10, label: "10 installments" },
  { value: 12, label: "12 installments" },
  { value: 15, label: "15 installments" },
  { value: 20, label: "20 installments" },
  { value: 30, label: "30 installments" },
];

export const EnrollmentFeeOptions: Array<{ value: number; label: string }> = [
  { value: 0, label: "No Fee" },
  { value: 1, label: "1%" },
  { value: 2, label: "2%" },
  { value: 3, label: "3%" },
  { value: 4, label: "4%" },
  { value: 5, label: "5%" },
  { value: 6, label: "6%" },
  { value: 7, label: "7%" },
  { value: 8, label: "8%" },
  { value: 9, label: "9%" },
  { value: 10, label: "10%" },
];

export const InsightOptions: Array<StringOptionsType> = [
  { value: "transactions", label: "Transactions" },
  { value: "outreach", label: "Outreach" },
];

export type OfficeOptionsType = {
  value?: OfficeStatus;
  label: string;
};

export const OfficeFilterOptions: Array<OfficeOptionsType> = [
  { value: undefined, label: "All Statuses" },
  { value: OfficeStatus.ACTIVE, label: "Active" },
  { value: OfficeStatus.INACTIVE, label: "Inactive" },
  { value: OfficeStatus.ONBOARDED, label: "Onboarded" },
];

export const PatientsOptions = [
  { value: undefined, label: "All Patients" },
  { value: 1, label: "Only with balance" },
];

export const RowsPerPageOptions = [
  { value: 10, label: "10" },
  { value: 25, label: "25" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];

export const DateRangeOptions = [
  { value: 0, label: "All Time" },
  { value: 1, label: "Month to Date" },
  { value: 2, label: "Today" },
  { value: 3, label: "Past 7 Days" },
  { value: 4, label: "Last Month" },
];

export type ReportOptionType = {
  value: string;
  label: string;
  type: PracticeManagement;
};

export const ReportUploadOptions: Array<ReportOptionType> = [
  { value: "payment", label: "Payment", type: PracticeManagement.WINOMS },
  { value: "patient", label: "Patient", type: PracticeManagement.WINOMS },
  { value: "appointment", label: "Appointment", type: PracticeManagement.WINOMS },
  { value: "dentrix", label: "Dentrix", type: PracticeManagement.DENTRIX },
  { value: "dentrix_ascend", label: "Aged Receivable", type: PracticeManagement.DENTRIX_ASCEND },
  { value: "dentrix_aged_receivable", label: "Aged Receivable", type: PracticeManagement.DENTRIX_NI },
  { value: "eaglesoft_patient", label: "Patient", type: PracticeManagement.EAGLESOFT_NI },
  { value: "eaglesoft_aged_receivable", label: "Aged Receivable", type: PracticeManagement.EAGLESOFT_NI },
  { value: "eaglesoft_aged_receivable", label: "Aged Receivable", type: PracticeManagement.EAGLESOFT },
  { value: "curve-balance", label: "Patient Balance (Curve)", type: PracticeManagement.CURVE },
  { value: "fuse_patient_seen", label: "Patient Seen", type: PracticeManagement.FUSE },
  { value: "fuse_patient_last_seen", label: "Patient Last Service", type: PracticeManagement.FUSE },
  { value: "fuse_aged_receivable", label: "Aged Receivable", type: PracticeManagement.FUSE },
  { value: "opendental_aged_receivable", label: "Aged Receivable", type: PracticeManagement.OPENDENTAL },
  { value: "opendental_patient_seen", label: "Patient Seen", type: PracticeManagement.OPENDENTAL_NI },
  { value: "opendental_aged_receivable", label: "Aged Receivable", type: PracticeManagement.OPENDENTAL_NI },
  { value: "patient_winoms_special", label: "Patient", type: PracticeManagement.WINOMS_SPECIAL },
  // { value: "denticon_patient", label: "Patient", type: PracticeManagement.DENTICON },
  { value: "denticon_aged_receivable", label: "Aged Receivable", type: PracticeManagement.DENTICON },
  { value: "softdent_patient", label: "Patient", type: PracticeManagement.SOFTDENT },
  { value: "softdent_aged_receivable", label: "Aged Receivable", type: PracticeManagement.SOFTDENT },
  { value: "sensicloud_patient", label: "Patient", type: PracticeManagement.SENSI_CLOUD },
];

export const PearlyUploadOptions = [
  { value: "pearly_processed", label: "PAYMENT PROCESSED" },
  { value: "pearly_prompted", label: "PAYMENT PROMPTED" },
  { value: "pearly_arstatus", label: "AR STATUS" },
];

export const timeZoneList = [
  { value: "America/New_York", label: "Eastern Time" },
  { value: "America/Chicago", label: "Central Time" },
  { value: "America/Denver", label: "Mountain Time" },
  { value: "America/Phoenix", label: "Mountain Time (no DST)" },
  { value: "America/Los_Angeles", label: "Pacific Time" },
  { value: "America/Anchorage", label: "Alaska Time" },
  { value: "America/Adak", label: "Hawaii-Aleutian" },
  { value: "Pacific/Honolulu", label: "Hawaii-Aleutian Time (no DST)" },
];

export const practiceList = Object.entries(PracticeManagement).map(([key, value]) => ({
  value: value,
  label: formatPMS(key as PracticeManagement),
}));
