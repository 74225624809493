import React from "react";

import { SkeletonText } from "@calcom/ui";

export default function WidgetSkeletonLoader() {
  return (
    <div>
      <div className="flex items-center gap-4">
        <SkeletonText className="h-8 w-28" />
        <div className="flex flex-col gap-1">
          <SkeletonText className="h-5 w-20" />
          <SkeletonText className="h-5 w-20" />
        </div>
      </div>
      <div className="my-6 border-b border-gray-200" />
      <div className="flex flex-col gap-1">
        <SkeletonText className="h-4 w-full" />
        <SkeletonText className="h-4 w-full" />
        <SkeletonText className="h-4 w-full" />
      </div>
    </div>
  );
}
