import React from "react";

type Props = {
  title: string;
  description: string;
  link?: string;
  linkText?: string;
};

export default function Empty({ title, description, link, linkText }: Props) {
  return (
    <div className="flex items-center justify-center p-10">
      <div className="flex flex-col items-center gap-5">
        <img src="/no-data.png" alt="no data" className=" w-36" />
        <h3 className="text-center text-lg font-bold">{title}</h3>
        <p className="text-center text-xs leading-relaxed text-gray-500">
          {description}
          {link ? (
            <a
              href="#"
              className="text-booking-new mb-5 hidden whitespace-nowrap text-sm font-medium leading-none underline md:mb-0 md:inline">
              {linkText}
            </a>
          ) : null}
        </p>
      </div>
    </div>
  );
}
