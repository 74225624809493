import React from "react";

import { SkeletonText } from "@calcom/ui";

export default function BarchartSkeletonLoader() {
  return (
    <div className="h-[461px] w-full rounded-md border border-gray-200 bg-white p-5 dark:bg-gray-800">
      <div className="flex justify-between border-b pb-6">
        <div className="flex flex-col items-center gap-4">
          <SkeletonText className="h-4 w-[100px] md:w-[200px]" />
          <SkeletonText className="h-4 w-[100px] md:w-[200px]" />
        </div>
        <SkeletonText className="h-10 w-[200px]" />
      </div>
      <div className="flex h-5/6 w-full items-end justify-around gap-1">
        <div className="flex h-full items-end gap-1">
          <SkeletonText className="h-3/4 w-[6px] md:w-5" />
          <SkeletonText className="h-2/4 w-[6px] md:w-5" />
        </div>
        <div className="flex h-full items-end gap-1">
          <SkeletonText className="h-3/5 w-[6px] md:w-5" />
          <SkeletonText className="h-2/5 w-[6px] md:w-5" />
        </div>
        <div className="flex h-full items-end gap-1">
          <SkeletonText className="h-3/6 w-[6px] md:w-5" />
          <SkeletonText className="h-2/6 w-[6px] md:w-5" />
        </div>
        <div className="flex h-full items-end gap-1">
          <SkeletonText className="h-3/5 w-[6px] md:w-5" />
          <SkeletonText className="h-2/5 w-[6px] md:w-5" />
        </div>
        <div className="flex h-full items-end gap-1">
          <SkeletonText className="h-2/3 w-[6px] md:w-5" />
          <SkeletonText className="h-1/3 w-[6px] md:w-5" />
        </div>
        <div className="flex h-full items-end gap-1">
          <SkeletonText className="h-3/4 w-[6px] md:w-5" />
          <SkeletonText className="h-2/4 w-[6px] md:w-5" />
        </div>
        <div className="flex h-full items-end gap-1">
          <SkeletonText className="h-3/5 w-[6px] md:w-5" />
          <SkeletonText className="h-2/5 w-[6px] md:w-5" />
        </div>
        <div className="flex h-full items-end gap-1">
          <SkeletonText className="h-3/6 w-[6px] md:w-5" />
          <SkeletonText className="h-2/6 w-[6px] md:w-5" />
        </div>
        <div className="flex h-full items-end gap-1">
          <SkeletonText className="h-3/5 w-[6px] md:w-5" />
          <SkeletonText className="h-2/5 w-[6px] md:w-5" />
        </div>
        <div className="flex h-full items-end gap-1">
          <SkeletonText className="h-2/3 w-[6px] md:w-5" />
          <SkeletonText className="h-1/3 w-[6px] md:w-5" />
        </div>
      </div>
    </div>
  );
}
