import dynamic from "next/dynamic";

import { formatNumber } from "@lib/practice/helper";

const ApexCharts = dynamic(() => import("react-apexcharts"), { ssr: false });

export default function PieTransactionChart({ data }) {
  const series = [...data];
  const options = {
    colors: ["#86CEBD", "#3DC5DD", "#CF716B"],
    stroke: {
      colors: ["transparent"],
      lineCap: "",
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              offsetY: 20,
              fontWeight: 700,
              fontSize: "32px",
            },
            total: {
              showAlways: true,
              show: true,
              label: "Total Transactions",
              fontSize: "0.875rem",
              color: "#6b7280",
              formatter: function (w) {
                const sum = w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
                return formatNumber(sum, "number").toString().split(".")[0];
              },
            },
            value: {
              show: true,
              offsetY: -20,
              fontWeight: 700,
              formatter: function (value) {
                return formatNumber(value, "number").toString().split(".")[0];
              },
            },
          },
          size: "80%",
        },
      },
    },
    grid: {
      padding: {
        top: -2,
      },
    },
    labels: ["Successful", "Refunds", "Failed"],
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: -25,
      markers: {
        radius: 2,
        width: 12,
        height: 12,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return "$" + formatNumber(value, "number").toString().split(".")[0];
        },
      },
    },
    xaxis: {
      labels: {
        formatter: function (value) {
          return "$" + formatNumber(value, "number").toString().split(".")[0];
        },
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
  };

  return (
    <div className="h-full w-full rounded-md border border-gray-200 bg-white p-5 dark:bg-gray-800">
      <div className="mb-4 flex justify-between border-b border-gray-200 pb-4 dark:border-gray-700">
        <div className="flex items-center">
          <div>
            <h5 className="text-base font-bold leading-9 text-black dark:text-white">
              Transaction Breakdown
            </h5>
            <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
              Visualize the breakdown of your transactions
            </p>
          </div>
        </div>
      </div>
      <ApexCharts options={options} series={series} type="donut" height={318} />
    </div>
  );
}
