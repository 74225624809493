import classNames from "classnames";
import React from "react";

export default function RedChip({ text, className }: { text: string; className?: string }) {
  return (
    <span
      className={classNames(
        "flex min-w-[100px] max-w-[140px] justify-center rounded-md bg-red-200 p-3 text-center text-xs uppercase text-red-800",
        className
      )}>
      {text}
    </span>
  );
}
