import type { PaymentCycle } from "@prisma/client";

import dayjs from "@calcom/dayjs";

export const formatPhoneNumber = (phoneNumber: string) => {
  const phone = phoneNumber.replace(/\D/g, "").slice(0, 10);
  if (phone.length >= 7) {
    return `(${phone.slice(0, 3)})${phone.slice(3, 6)}-${phone.slice(6)}`;
  } else if (phone.length >= 4) {
    return `(${phone.slice(0, 3)})${phone.slice(3)}`;
  }
  return phone;
};

export const validatePhoneNumber = (phoneNumber: string) => {
  const phone = phoneNumber.replace(/\D/g, "").slice(0, 10);
  if (phone.length >= 10) {
    return true;
  } else return false;
};

export const formatBirthDate = (birthday: string) => {
  const birtDate = birthday.replace(/\D/g, "").slice(0, 8);
  if (birtDate.length >= 5) {
    return `${birtDate.slice(0, 2)}/${birtDate.slice(2, 4)}/${birtDate.slice(4)}`;
  } else if (birtDate.length >= 3) {
    return `${birtDate.slice(0, 2)}/${birtDate.slice(2)}`;
  }
  return birtDate;
};

export const ReadFileToBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
};

export const formatIntNumber = (number: number | undefined | null) => {
  if (number) {
    const formattedNumber = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedNumber;
  } else {
    return 0;
  }
};

export const formatNumber = (number: number | undefined | null, type?: "balance" | "number") => {
  if (number) {
    if (type === "number") {
      return new Intl.NumberFormat("en-US").format(number);
    } else {
      return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(number);
    }
  } else {
    return 0;
  }
};

export const formatTimezone = (timezone: string) => {
  if (timezone) {
    const city = timezone.split("/").pop();
    return city?.replace("_", " ");
  }
};

export const getPaymentDate = (firstDate: Date, count: number, period: PaymentCycle) => {
  let unit: dayjs.ManipulateType;

  switch (period) {
    case "WEEKLY":
      unit = "week";
      break;
    case "BIWEEKLY":
      unit = "week";
      break;
    case "MONTHLY":
      unit = "month";
      break;
    case "YEARLY":
      unit = "year";
      break;
    default:
      unit = "day";
      break;
  }

  return dayjs(firstDate)
    .add(period == "BIWEEKLY" ? count * 2 : count, unit)
    .toDate();
};

export const getCategoryFromCode = (code: string) => {
  const mainCategory = code.slice(0, 2);
  const subCategory = +code.charAt(2);

  switch (mainCategory) {
    case "D0":
      return "Diagnostic";
    case "D1":
      return "Preventive";
    case "D2":
      return "Restorative";
    case "D3":
      return "Endodontics";
    case "D4":
      return "Periodontics";
    case "D5":
      return subCategory < 9 ? "Prosthodontics-removable" : "Maxillofacial Prosthodontics";
    case "D6":
      return subCategory < 2 ? "Implant Services" : "Prosthodontics, fixed";
    case "D7":
      return "Oral & Maxillofacial Surgery";
    case "D8":
      return "Orthodontics";
    case "D9":
      return "Adjunctive General Services";
    default:
      return code;
  }
};
